import React, { useCallback, useEffect, useState } from "react";
import defaultStyle from "./L4200_ViewerContent.module.css"
import { FetchMapFgBg, FilteredTaskResultForNodes, GeoInfo, ObjectInterface } from "../interfaces";
import { useGlobalContext } from "../context/GlobalContext";
import Spinner from "../components/C0100_Spinner";
import useGetFetch from "../hooks/useGetFetch";
import { isDateWithinRange, isEmpty } from "../utils/commonFunctions";
import gslogo_gray from "../assets/images/gslogo_gray.png"
import ViewerContentViewer from "./L4210_ViewerContentMain"
import ViewerContentList from "./L4220_ViewerContentList"

interface ViewerContentProps {
  styleUrl?: string;
}


const ViewerContent: React.FC<ViewerContentProps> = ({
  styleUrl,
}) => {


  /************************
   * Set styles custom / default
   *************************/
  const [style, setStyle] = useState<ObjectInterface>({});

  useEffect(() => {
    let isError = true;

    if (styleUrl) {
      import(styleUrl)
        .then((cssModule: ObjectInterface) => {
          setStyle(cssModule);
          isError = false;
        })
        .catch((error) => { console.error("error in importing custom cssfile: ", error) })
    }

    if (isError) {
      setStyle(defaultStyle);
    }
  }, [styleUrl])
  /***************************/



  /****************************
   * global variables
   ****************************/
  const {
    gSelectedSite,
    gSelectedSpaceDb,
    gSelectedPatrolUTCTimeStampsRange,
    gSelectedActionItems,
    gSelectedGroupByItems,
    gSelectedFloorName,
    gSelectedMapName,
    gFilteredTaskResultForNodes,
    gIdsForCheckedTaskResult,
    gUserAuthDetail,
    gMapLoading,
    updateGlobalContext,
  } = useGlobalContext();
  /*****************************/


  /****************************
* functions for spinner
****************************/
  const [isSpinnerOpen, setIsSpinnerOpen] = useState(true);
  const [spinnerMessage, setSpinnerMessage] = useState<string>("Loading data..");

  const openSpinner = useCallback((message: string) => {
    setSpinnerMessage(message);
    setIsSpinnerOpen(true);
  }, []);

  const closeSpinner = useCallback(() => {
    setSpinnerMessage("");
    setIsSpinnerOpen(false);
  }, []);


  const onSpinnerClose = () => {
    closeSpinner();
  }
  /**************************/


  /*****************************
   * Generate Filtered Task Results
   ****************************/

  const [filteredTaskResultForNodesForThisView, setFilteredTaskResultForNodesForThisView] = useState<FilteredTaskResultForNodes>({});


  useEffect(() => {

    console.log("DEBUG gFilteredTaskResultForNodes!!!!!!\n", gFilteredTaskResultForNodes);
    if (!gFilteredTaskResultForNodes) return;



    if (!isEmpty(gSelectedMapName) && !isEmpty(gSelectedSpaceDb)) {
      const theFilteredTaskResultForNodes: FilteredTaskResultForNodes = {};
      Object.entries(gFilteredTaskResultForNodes).forEach(([key, theTaskResultForNode]) => {

        const willShowEverything = gUserAuthDetail?.is_master ? true : (theTaskResultForNode.action_type_list.includes("CAM360_IMAGE") ||
          theTaskResultForNode.action_type_list.includes("CAM360_VIDEO") ||
          theTaskResultForNode.action_type_list.includes("VOICE_RECORDING"));

        const willInclude = (
          // compareItemsInTwoArray<string>(gSelectedPatrolUTCTimeStamps, theTaskResultForNode.patrol_utc_timestamp_list) &&
          // compareItemsInTwoArray<string>(gSelectedActionItems, theTaskResultForNode.action_type_list) &&
          (gSelectedMapName ? gSelectedMapName === theTaskResultForNode.map_title : true) &&
          (gSelectedFloorName ? gSelectedFloorName === theTaskResultForNode.filter.layer_title : true) &&
          (gSelectedSpaceDb ? gSelectedSpaceDb === theTaskResultForNode.filter.space_db_title : true) && willShowEverything
        )

        const dateStampList = theTaskResultForNode.patrol_utc_timestamp_list;

        let isWithinDateRange = false;
        for (let i = 0; i < dateStampList.length; i++) {
          if (gSelectedPatrolUTCTimeStampsRange && isDateWithinRange(dateStampList[i], gSelectedPatrolUTCTimeStampsRange.from, gSelectedPatrolUTCTimeStampsRange.to)) {
            isWithinDateRange = true;
            break;
          }
        }


        if (willInclude && isWithinDateRange) {
          theFilteredTaskResultForNodes[key] = theTaskResultForNode
        }
      });

      setFilteredTaskResultForNodesForThisView(theFilteredTaskResultForNodes);
      // updateGlobalContext({ gMapLoading: false })
    }


    if (isEmpty(gSelectedMapName) && isEmpty(gSelectedSpaceDb)) {
      // setFilteredTaskResultForNodesForThisView({});

    }
  }, [gFilteredTaskResultForNodes, gSelectedActionItems, gSelectedFloorName, gSelectedMapName, gSelectedPatrolUTCTimeStampsRange, gSelectedSpaceDb, openSpinner]);
  // [gFilteredTaskResultForNodes, gSelectedActionItems, gSelectedFloorName, gSelectedMapName, gSelectedSpaceDb, openSpinner]



  /**********************
   **** fetching: map ***
   **********************/


  const {
    data: data_map_fetch,
    loading: loading_map_fetch,
    error: error_map_fetch,
  } = useGetFetch<FetchMapFgBg>(
    `database/map/get_map_fgbg?map=${gSelectedMapName}&site_folder_name=${gSelectedSite?.subfolder_title}`,
    [gSelectedMapName]
  );

  const [mapData, setMapData] = useState<FetchMapFgBg | null>();

  useEffect(() => {
    if (data_map_fetch) {
      setMapData(data_map_fetch);
      updateGlobalContext({
        gMapLoading: false
      })

    }
  }, [data_map_fetch]);

  useEffect(() => {
    if (gSelectedMapName === "") {
      setMapData(null);
      updateGlobalContext({
        gMapLoading: false
      })
    }
  }, [gSelectedMapName]);
  /*************************************/



  useEffect(() => {
    if (gFilteredTaskResultForNodes && !mapData && !filteredTaskResultForNodesForThisView) {
      openSpinner("Loading map image data..");
    } else {
      closeSpinner();
    }
  }, [closeSpinner, filteredTaskResultForNodesForThisView, gFilteredTaskResultForNodes, mapData, openSpinner]);


  const [currentGeoInfo, setCurrentGeoInfo] = useState<GeoInfo | undefined>();

  const handleCurrentGeoInfo = useCallback((geoInfo: GeoInfo) => {
    if (geoInfo) {
      setCurrentGeoInfo(geoInfo);
    }
  }, []);

  type SCREEN_MODE = "NO_DATA" | "LOADING" | "LOADED" | "ITEM_SELECTED";
  const [screenMode, setScreenMode] = useState<SCREEN_MODE>("NO_DATA")

  useEffect(() => {
    let screenMode: SCREEN_MODE;

    // const IsRawDataReady = !isEmpty(gSelectedMapName) && !isEmpty(gFilteredTaskResultForNodes) ? true : false;
    const IsRawDataReady = !isEmpty(gSelectedMapName);
    const IsDataForViewerContentListReady = (!isEmpty(mapData) && !isEmpty(filteredTaskResultForNodesForThisView)) ? true : false;
    const IsDataForViewerContentViewReady = (!isEmpty(gIdsForCheckedTaskResult)) ? true : false;


    if ((IsRawDataReady) && (gMapLoading)) {
      screenMode = "LOADING";
      setScreenMode(screenMode);
      setIsSpinnerOpen(() => true);
      // console.log("LOADING LOADING LOADING");
      // console.log("LOADING LOADING LOADING");
      // console.log("IsRawDataReady:", IsRawDataReady);
      // console.log("gMapLoading:", gMapLoading);
      // console.log("gSelectedMapName:", gSelectedMapName);
      // console.log("gFilteredTaskResultForNodes:", gFilteredTaskResultForNodes);
      console.log("LOADING LOADING LOADING");
      return;
    }

    if ((IsRawDataReady) && (gMapLoading === false) && (IsDataForViewerContentListReady === true)) {
      screenMode = "LOADED";
      setScreenMode(screenMode);
      setIsSpinnerOpen(false);
      console.log("LOADED  LOADED  LOADED");
      // console.log("LOADED  LOADED  LOADED");
      // console.log("LOADED  LOADED  LOADED");

      updateGlobalContext({
        gMapLoading: false
      })
      return;
    }

    // if ((IsRawDataReady) && (gMapLoading === false) && (IsDataForViewerContentListReady === false)) {
    //   screenMode = "NO_DATA";
    //   setScreenMode(screenMode);
    //   setIsSpinnerOpen(false);


    //   return;
    // }

    console.log("NO DATA NO DATA NO DATA");
    // console.log("NO DATA NO DATA NO DATA");
    // console.log("IsRawDataReady:", IsRawDataReady);
    // console.log("gMapLoading:", gMapLoading);
    // console.log("NO DATA NO DATA NO DATA");
    // console.log("NO DATA NO DATA NO DATA");

    screenMode = "NO_DATA";
    setScreenMode(screenMode);
    setIsSpinnerOpen(false);

    updateGlobalContext({
      gMapLoading: false
    })

    // if ((IsRawDataReady === true) && (IsDataForViewerContentListReady === false) && (IsDataForViewerContentViewReady === false)) {
    //   if (gMapLoading) {
    //     setIsSpinnerOpen(false);
    //     screenMode = "NO_DATA";
    //     setScreenMode(screenMode);
    //   } else {
    //     screenMode = "LOADING";
    //     setScreenMode(screenMode);
    //     setIsSpinnerOpen(true);
    //   }
    //   return;
    // }


    // if (((IsRawDataReady === true) && (IsDataForViewerContentListReady === true) && (IsDataForViewerContentViewReady === false))) {
    //   screenMode = "LOADED";
    //   setIsSpinnerOpen(false);
    //   setScreenMode(screenMode);
    //   setCurrentGeoInfo(undefined);
    //   return;
    // }

    // if ((IsRawDataReady === true) && (IsDataForViewerContentListReady === true) && (IsDataForViewerContentViewReady === true)) {
    //   screenMode = "ITEM_SELECTED";
    //   setIsSpinnerOpen(false);
    //   setScreenMode(screenMode);
    //   return;
    // }

  }, [gMapLoading, gSelectedMapName, filteredTaskResultForNodesForThisView, gFilteredTaskResultForNodes, gIdsForCheckedTaskResult, mapData]);



  return (
    <div className={style.layout}>
      {isSpinnerOpen && <Spinner
        isOpen={isSpinnerOpen}
        onClose={onSpinnerClose}
        isHideTopMenu={true}
        message={spinnerMessage}
      />}

      {(screenMode === "LOADED") && gIdsForCheckedTaskResult.length !== 0 &&
        <ViewerContentViewer updateCurrentGeoInfo={handleCurrentGeoInfo} />}
      {(screenMode === "LOADED") && gIdsForCheckedTaskResult.length === 0 &&
        <div className={style.blank__content__view}>
          <img className={style.gslogo} src={gslogo_gray} alt="logo" />오른쪽 촬영 아이템을 선택하세요.</div>}
      {/* {(screenMode === "LOADED") &&
        <div className={style.blank__content__view}>
          <img className={style.gslogo} src={gslogo_gray} alt="logo" />오른쪽 촬영 아이템을 선택하세요.</div>} */}
      {(screenMode === "LOADED") &&
        <ViewerContentList
          mapData={mapData!}
          filteredTaskResultForNodesForThisView={filteredTaskResultForNodesForThisView}
          currentGeoInfo={currentGeoInfo} />}
      {(screenMode === "NO_DATA") && <div className={`${style.blank__content__view} ${style.nodata}`}>
        <img className={style.gslogo} src={gslogo_gray} alt="logo" />검색 결과가 없습니다.</div>}

      {/* {((screenMode === "LOADED") || (screenMode === "ITEM_SELECTED")) &&
        <ViewerContentView updateCurrentGeoInfo={handleCurrentGeoInfo} />}
      {((screenMode === "LOADED") || (screenMode === "ITEM_SELECTED")) &&
        <ViewerContentList
          mapData={mapData!}
          filteredTaskResultForNodesForThisView={filteredTaskResultForNodesForThisView}
          currentGeoInfo={currentGeoInfo} />} */}
    </div>
  )
}

export default ViewerContent;
