import React, { memo, useEffect, useState, MouseEvent } from 'react';
import styles from './C0500_TaskImageListRow.module.css'
import { ObjectInterface, TaskResultForNode, TaskResultInterface } from '../interfaces';
import { convertUTCsDateTimeToLocal, getFileName, getUrlFromOldFileHyperLink, isEmpty } from '../utils/commonFunctions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlasses, faFilm, faImage, faVideo, faPanorama, faTemperatureHalf, faMicrophone, faLocationCrosshairs, faHashtag, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useGlobalContext } from '../context/GlobalContext';


interface TaskImageListRowProps {
  layoutStyle?: string;
  taskResult: TaskResultForNode;
  onClickHandler: (id: string) => void;
  onMouseOverHandler: (id: string) => void;
  isChecked: boolean;
}

const TaskImageListRow = ({
  layoutStyle,
  taskResult,
  onClickHandler,
  onMouseOverHandler,
  isChecked,
}: TaskImageListRowProps) => {

  const { gBaseUrl, gSelectedSite, updateGlobalContext } = useGlobalContext();

  const isSensor = taskResult.action_type_list.includes("SENSOR");
  const isImage = taskResult.action_type_list.includes("CAM_COMBINED_IMAGE");
  const isVideo = taskResult.action_type_list.includes("CAM_COMBINED_VIDEO");
  const is360Cam = taskResult.action_type_list.includes("CAM360_IMAGE");
  const is360Video = taskResult.action_type_list.includes("CAM360_VIDEO");
  const isThermal = taskResult.action_type_list.includes("THERMAL_MSG");
  const isVoice = taskResult.action_type_list.includes("VOICE_RECORDING");


  const [thumbnailUrl, setThumbnailUrl] = useState<string>("");
  const theFirstRecord = taskResult.record[Object.keys(taskResult.record)[0]];
  const thumbnailHyperLink = theFirstRecord.thumbnail_link!.slice(1)

  useEffect(() => {
    if (isEmpty(gSelectedSite)) return;

    const theThumbnailUrl = getUrlFromOldFileHyperLink(
      gBaseUrl,
      "cam_images",
      gSelectedSite!.subfolder_title,
      thumbnailHyperLink
    )

    setThumbnailUrl(theThumbnailUrl);
  }, []);

  const [timeStampObject, setTimeStampObject] = useState<ObjectInterface>({});

  useEffect(() => {
    const thisTimeStampObject: ObjectInterface = {}

    taskResult.patrol_utc_timestamp_list.forEach((theTime, index) => {
      thisTimeStampObject[index + 1] = convertUTCsDateTimeToLocal(theTime);
    })

    setTimeStampObject(thisTimeStampObject);

  }, [taskResult.patrol_utc_timestamp_list]);



  const clickHandler = () => {
    onClickHandler(taskResult.id);
  }

  const [isOnMouseOver, setIsOnMouseOver] = useState<boolean>(false);

  const mouseOverHandler = (e: MouseEvent<HTMLButtonElement>) => {
    if (isOnMouseOver) return;

    setIsOnMouseOver(true);
    // console.log("OVER target1:", e.currentTarget)
    // console.log("MOUSE OVER", taskResult.id)
    onMouseOverHandler(taskResult.id);
    // updateGlobalContext({ gIdForHighlitedTaskResult: taskResult.id });

  }

  const mouseLeaveHandler = (e: MouseEvent<HTMLButtonElement>) => {
    if (isOnMouseOver) {
      setIsOnMouseOver(false);
      // updateGlobalContext({ gIdForHighlitedTaskResult: "-" });
      onMouseOverHandler("");

    }

    // console.log("leave target2:", e.currentTarget)
  }
  const theLayoutStyle = `${layoutStyle ? layoutStyle : ""} ${styles.layout ? styles.layout : ""} ${isChecked ? styles.checked : ""}`
  const theContainerStyle = `${styles.layout ? styles.layout : ""} ${isChecked ? styles.checked : ""}`

  return (
    <button
      className={`${styles.layout} ${isChecked ? styles.checked : ""}`}
      onClick={clickHandler}
      onMouseOver={mouseOverHandler}
      onMouseLeave={mouseLeaveHandler}
      style={{ backgroundImage: `url(${thumbnailUrl})` }}
    >
      <div className={styles.description__field}>
        <ul className={styles.txt__ul}>
          <li className={styles.txt__li} >건물명: {taskResult.filter.space_db_title} </li>
          <li className={styles.txt__li} >촬영층: {taskResult.filter.layer_title} </li>
          <li className={styles.txt__li} >순찰명: {taskResult.filter.patrol_title} </li>
          <li className={styles.txt__li} >도면정보: {taskResult.map_title} </li>
          <li className={styles.txt__li}>촬영횟수: ({taskResult.patrol_utc_timestamp_list.length})
            <FontAwesomeIcon icon={faCircleInfo} title={JSON.stringify(timeStampObject, null, 2)} style={{ marginLeft: `10px` }} />
          </li>
        </ul>
      </div>
      <div className={`${styles.action__icons__container} ${isChecked ? styles.checked__bg : ""}`}>
        <div className={`${styles.action__icon} ${isSensor ? styles.action__on : styles.action__off}`}><FontAwesomeIcon icon={faGlasses} /></div>
        <div className={`${styles.action__icon} ${isImage ? styles.action__on : styles.action__off}`}><FontAwesomeIcon icon={faImage} /></div>
        <div className={`${styles.action__icon} ${isVideo ? styles.action__on : styles.action__off}`}><FontAwesomeIcon icon={faVideo} /></div>
        <div className={`${styles.action__icon} ${is360Cam ? styles.action__on : styles.action__off}`}><FontAwesomeIcon icon={faPanorama} /></div>
        <div className={`${styles.action__icon} ${is360Video ? styles.action__on : styles.action__off}`}><FontAwesomeIcon icon={faPanorama} /></div>
        <div className={`${styles.action__icon} ${isThermal ? styles.action__on : styles.action__off}`}><FontAwesomeIcon icon={faTemperatureHalf} /></div>
        <div className={`${styles.action__icon} ${isVoice ? styles.action__on : styles.action__off}`}><FontAwesomeIcon icon={faMicrophone} /></div>
      </div>

    </button>
  );
};


export default memo(TaskImageListRow);
