import React, { useState, useEffect } from "react";
import { TextField, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Chip, Checkbox, ListItemIcon, Select, MenuItem, InputLabel, FormControl, Divider, DialogContentText } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import useGetFetch2 from "../hooks/useGetFetch2";
import usePostFetch from "../hooks/usePostFetch";
import useDeleteFetch from "../hooks/useDeleteFetch";
import { DBMasterSite, ObjectInterface, Users } from "../interfaces";
import styles from "./L0160_UserManagement.module.css";

interface UserListResponse {
  result: string;
  data: Users[];
}

interface SiteGetFetchInterface {
  success: boolean;
  data: DBMasterSite[];
}

const UserManagement = () => {
  const { data: users, loading: usersLoading, error: usersError, getFetchFunc } = useGetFetch2<UserListResponse>();
  const { data: updateUserResponse, loading: updateUserLoading, error: updateUserError, postFetchFunc } = usePostFetch<Users>();
  const { data: createUserResponse, loading: createUserLoading, error: createUserError, postFetchFunc: createFetchFunc } = usePostFetch<Users>();
  const { data: deleteUserResponse, loading: deleteUserLoading, error: deleteUserError, deleteFetchFunc } = useDeleteFetch<any>();

  const { data: site_data, loading: site_loading, error: site_error, getFetchFunc: siteGetFetchFunc } = useGetFetch2<SiteGetFetchInterface>();
  const [siteId2Name, setSiteId2Name] = useState<ObjectInterface>({});
  const [siteName2Id, setSiteName2Id] = useState<ObjectInterface>({});

  useEffect(() => {
    siteGetFetchFunc(`database/site/getlist`);
  }, []);

  useEffect(() => {
    const thisId2Name: ObjectInterface = {};
    const thisName2Id: ObjectInterface = {};

    if (site_data) {
      site_data.data.forEach((theSite) => {
        thisId2Name[String(theSite.data_id)] = theSite.name;
        thisName2Id[theSite.name] = String(theSite.data_id);
      });

      setSiteId2Name(thisId2Name);
      setSiteName2Id(thisName2Id);
    }
  }, [site_data]);

  const [editUser, setEditUser] = useState<any | null>(null);
  const [open, setOpen] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);

  useEffect(() => {
    getFetchFunc("users");
  }, []);

  useEffect(() => {
    if ((updateUserResponse && !updateUserError) || (createUserResponse && !createUserError)) {
      getFetchFunc("users");
      handleClose();
    }
  }, [updateUserResponse, updateUserError, createUserResponse, createUserError]);

  useEffect(() => {
    if (deleteUserResponse && !deleteUserError) {
      getFetchFunc("users");
    }
  }, [deleteUserResponse, deleteUserError]);

  const handleEditClick = (user: any) => {
    setEditUser(user);
    setIsNewUser(false);
    setOpen(true);
  };

  const handleAddUserClick = () => {
    setEditUser({
      user_id: "",
      password: "",
      is_master: false,
      description: "",
      sites_ids_possible_admin: [],
      sites_ids_possible_operation: [],
      sites_ids_possible_edit: [],
      sites_ids_possible_view: []
    });
    setIsNewUser(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditUser(null);
  };

  const handleSave = () => {
    if (editUser) {
      const userPayload: Users = {
        user_id: editUser.user_id,
        password: editUser.password,
        is_master: editUser.is_master,
        description: editUser.description,
        sites_ids_possible_admin: editUser.sites_ids_possible_admin,
        sites_ids_possible_operation: editUser.sites_ids_possible_operation,
        sites_ids_possible_edit: editUser.sites_ids_possible_edit,
        sites_ids_possible_view: editUser.sites_ids_possible_view
      };

      console.log(userPayload);

      if (isNewUser) {
        createFetchFunc("users/create", userPayload);  // Assuming a create endpoint
      } else {
        postFetchFunc(`users/update`, userPayload);  // Assuming an update endpoint
      }
    }
  };

  const handleDeleteUser = (userId: string) => {
    deleteFetchFunc(`users/${userId}`);
  };

  const handleChipDelete = (field: string, index: number) => {
    if (editUser) {
      const updatedArray = [...editUser[field]];
      updatedArray.splice(index, 1);
      setEditUser({ ...editUser, [field]: updatedArray });
    }
  };

  const handleAddSiteId = (field: string, siteId: string) => {
    if (editUser) {
      if (!editUser[field].includes(siteId)) {
        const updatedArray = [...editUser[field], siteId];
        setEditUser({ ...editUser, [field]: updatedArray });
      }
    }
  };

  const handleIsMasterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (editUser) {
      setEditUser({ ...editUser, is_master: event.target.checked });
    }
  };

  return (
    <div className={styles.container}>
      <h2>User Management</h2>
      {usersLoading ? (
        <p>Loading users...</p>
      ) : usersError ? (
        <p>Error loading users: {usersError}</p>
      ) : (
        <List sx={{ width: '90%', bgcolor: 'background.paper' }}>
          {users?.data.map(user => (
            <ListItem key={user.user_id}>
              <ListItemText primary={user.user_id} />
              <ListItemIcon>
                <Checkbox checked={user.is_master} />
              </ListItemIcon>
              <IconButton edge="end" aria-label="edit" onClick={() => handleEditClick(user)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteUser(user.user_id)}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      )}

      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleAddUserClick}
      >
        Add User
      </Button>

      <Dialog open={open} onClose={handleClose} sx={{ '& .MuiDialog-paper': { height: '800px', width: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'stretch' } }}>
        <DialogTitle>{isNewUser ? "Add User" : "Edit User"}</DialogTitle>
        <DialogContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', gap: 2, overflowY: 'auto' }}>
          <TextField
            label="User ID"
            value={editUser?.user_id}
            onChange={e => setEditUser({ ...editUser, user_id: e.target.value })}
            sx={{ marginTop: 2 }}
            fullWidth
            disabled={!isNewUser}
          />
          {isNewUser && (
            <TextField
              label="Password"
              type="password"
              value={editUser?.password}
              onChange={e => setEditUser({ ...editUser, password: e.target.value })}
              fullWidth
            />
          )}
          <TextField
            label="Description"
            value={editUser?.description}
            onChange={e => setEditUser({ ...editUser, description: e.target.value })}
            fullWidth
          />
          <FormControl fullWidth sx={{ marginTop: 0 }}>
            <Checkbox
              checked={editUser?.is_master || false}
              onChange={handleIsMasterChange}
              id="is-master-checkbox"
            />
            <InputLabel htmlFor="is-master-checkbox">Is Master?</InputLabel>
          </FormControl>

          <Divider />
          <DialogContentText>Choose accessible sites</DialogContentText>
          {["sites_ids_possible_admin", "sites_ids_possible_operation", "sites_ids_possible_edit", "sites_ids_possible_view"].map(field => (
            <div key={field} className={styles.chipContainer}>
              <FormControl fullWidth>
                <InputLabel>{`for ${field.replace("sites_ids_possible_", "").replace("_", " ")}`}</InputLabel>
                <Select
                  value=""
                  onChange={(e) => handleAddSiteId(field, e.target.value as string)}
                  fullWidth
                >
                  {Object.keys(siteName2Id).map((siteName) => (
                    <MenuItem key={siteName2Id[siteName]} value={siteName2Id[siteName]}>
                      {siteName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {editUser?.[field]?.map((siteId: string, index: number) => siteId2Name[siteId] && (
                <Chip
                  key={index}
                  label={siteId2Name[siteId]}
                  onDelete={() => handleChipDelete(field, index)}
                  sx={{ marginTop: 1 }}
                />
              ))}
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={updateUserLoading || createUserLoading}>
            {(updateUserLoading || createUserLoading) ? "Saving..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserManagement;
