import React, { useState, memo, useRef, useEffect } from "react";
import defaultStyle from "./C0440_ToggleButtonGroup.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ObjectInterface } from "../interfaces";
import { renderingConsoleLog } from "../utils/commonFunctions";

interface ToggleButtonGroupProps {
  items: ObjectInterface;
  cbFunc: (key: string, value: string) => void;
  defaultKey: string,
  styleObj?: ObjectInterface;
  itemLoading: boolean;
  headerTitle?: string;
  willDisplayKey?: boolean;
}

const ToggleButtonGroup: React.FC<ToggleButtonGroupProps> = ({
  items,
  cbFunc,
  styleObj,
  itemLoading,
  headerTitle,
  defaultKey,
  willDisplayKey,
}) => {

  // Performance Checker
  const renderCounter = useRef<number>(0);
  renderingConsoleLog({ componentName: "Toggle Button Group", cnt: renderCounter.current += 1 })


  /****** fade-in effect ************************/
  const [opacity, setOpacity] = useState(0);
  const transitionStyle = { transition: 'opacity 0.5s ease', opacity }
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpacity(1);
    }, 10);
  }, []);
  /*********************************************/


  /************************
 * Set styles custom / default
 */
  const [style, setStyle] = useState<ObjectInterface>({});

  useEffect(() => {
    if (styleObj) {
      setStyle(styleObj);
    } else {
      setStyle(defaultStyle);
    }

  }, [styleObj])
  /***************************/

  const [selectedKey, setSelectedKey] = useState<string>(defaultKey);

  willDisplayKey = willDisplayKey ? true : false;

  const handleItemClick = (key: string, value: string) => {
    if (selectedKey === key) {
      setSelectedKey("");
      cbFunc("", "");
    } else {
      setSelectedKey(key);
      cbFunc(key, value);
    }
  };

  useEffect(() => {
    console.log("DEBUG defaultKey: ", defaultKey)
    setSelectedKey(defaultKey);
  }, [defaultKey])


  // console.log("[DEBUG] from togglebuttongroup:", items)

  return (
    <div className={style.layout} style={{ ...transitionStyle }}>
      {headerTitle && <div className={style.toggle__header}>{headerTitle}</div>}
      <div className={style.toggle__container}>
        {!itemLoading &&
          items &&
          Object.keys(items).map((key) => (
            <button
              key={key}
              className={`${style.toggle__button} ${selectedKey === key ? style.active : ""
                }`}
              onClick={() => handleItemClick(key, items[key])}
            >
              {willDisplayKey ? key : items[key]}
            </button>
          ))}
      </div>
    </div>
  );
};

export default memo(ToggleButtonGroup);

