import React, { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ObjectInterfaceAny } from "../interfaces";
import { useGlobalContext } from "../context/GlobalContext";
import style from './L6000_MapBuilderPage.module.css';

interface PostMessageEvent extends MessageEvent {
  data: {
    type: string;
    route: string;
  };
}

const MapBuilderPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleReceiveMessage = (event: PostMessageEvent) => {
      if (event.origin !== window.location.origin) {
        // Security check: ensures messages are received from the same origin
        return;
      }

      // Check for a specific type of message, e.g., a routing request
      if (event.data.type === 'navigate') {
        navigate(event.data.route); // Navigate using React Router
      }
    };

    window.addEventListener('message', handleReceiveMessage);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handleReceiveMessage);
    };
  }, [navigate]);

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { gBaseUrl, gApiKey, gSessionToken, gSelectedSite, gUserId, gUserAuthDetail } = useGlobalContext();

  const sendMessageToIframe = useCallback(() => {
    const iframe = iframeRef.current;

    const dataToOperator: ObjectInterfaceAny = {
      'gUserId': gUserId,
      'gUserAuthDetail': gUserAuthDetail,
      'gBaseUrl': gBaseUrl,
      'gApiKey': gApiKey,
      'gSessionToken': gSessionToken,
      'gSelectedSite': gSelectedSite
    }

    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(JSON.stringify(dataToOperator));
    }
  }, [gApiKey, gBaseUrl, gSelectedSite, gSessionToken])



  return (
    <iframe
      ref={iframeRef}
      src="/map-module/map_editor/mapeditor.html"
      title="operation"
      className={style.layout}
      onLoad={sendMessageToIframe}
    />
  )
}

export default MapBuilderPage;