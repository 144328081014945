import React, { ReactEventHandler, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';

import "./App.css";
import { useGlobalContext } from "./context/GlobalContext";
import ViewerPage from "./layouts/L4000_ViewerPage";
import LoginPage from "./layouts/L0100_LoginPage";
import SiteSelector from "./layouts/L1100_SiteSelector";
import { DBMasterSite } from "./interfaces";
import FuncMenu from "./layouts/L1200_FuncMenu";
import OperationPage from "./layouts/L5000_OperationPage";
import MapBuilderPage from "./layouts/L6000_MapBuilderPage";
import SpaceBuilderPage from "./layouts/L6100_SpaceBuilderPage";
import MobilePage from "./layouts/L7000_MobilePage";

import AdminPannel from "./layouts/L0150_AdminPannel";
import SpinnerLayout from "./layouts/L0050_SpinnerLayout";

function App() {
  const { gIsLoggedIn, updateGlobalContext, gSelectedSite, gUserAuthDetail } = useGlobalContext();
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {

    if (gIsLoggedIn === true) return; //if the page has been already logged in

    console.log("OperationPage is called");
    const bc = new BroadcastChannel('gs_robot_session');

    const handleMessage = (e: MessageEvent) => {
      if (e.data) {
        const data = e.data;
        console.log("bc.onmessage e:\n", e);

        updateGlobalContext({
          'gIsLoggedIn': data.gIsLoggedIn,
          'gUserId': data.gUserId,
          'gUserAuthDetail': data.gUserAuthDetail,
          'gBaseUrl': data.gBaseUrl,
          'gApiKey': data.gApiKey,
          'gSessionToken': data.gSessionToken,
          'gSelectedSite': data.gSelectedSite
        });

        const where = data.where;
        navigate(where);
      }

      // Close the BroadcastChannel after processing the message
      bc.close();
    };

    bc.addEventListener('message', handleMessage);

    // Clean up the event listener and close the channel on component unmount
    return () => {
      bc.removeEventListener('message', handleMessage);
      bc.close();
    };
  }, [navigate, updateGlobalContext]);

  useEffect(() => {
    console.log("* gIsLogginedIn: ", gIsLoggedIn)
    if (gIsLoggedIn === true) {
      const widthToIdentifyMobile = 600 //px
      const isMobile = window.innerWidth <= widthToIdentifyMobile;


      console.log("window.innerWidth: ", window.innerWidth);
      console.log("widthToIdentifyMobile: ", widthToIdentifyMobile);

      // If the device is a mobile phone and the user is logged in, navigate to the mobile page
      if (isMobile && gUserAuthDetail?.is_master) {
        navigate("/mobile"); // Replace "/mobile" with your mobile page route
        return
      }

      // if (gSelectedSite) {
      //   navigate("/func")
      //   return
      // }

      // navigate("/site")
      // navigate("/management/users")
    } else {
      const currentPath = location.pathname;

      if (currentPath === "/spinner") {
        navigate("/spinner")
      } else {
        navigate("/")
      }
    }
  }, [gIsLoggedIn]);
  // }, [gIsLoggedIn, gSelectedSite]);


  return (
    <Routes>
      {/* <Route path="/" element={gIsLoggedIn ? <SiteSelector cbFunc={updateSelectedSite} /> : <LoginPage />} />; */}
      <Route path="/" element={<LoginPage />} />
      <Route path="/spinner" element={<SpinnerLayout />} />
      <Route path="/site" element={<SiteSelector />} />
      <Route path="/func" element={<FuncMenu />} />
      <Route path="/operation" element={<OperationPage />} />
      <Route path="/viewer" element={<ViewerPage />} />
      <Route path="/mapbuilder" element={<MapBuilderPage />} />
      <Route path="/spacebuilder" element={<SpaceBuilderPage />} />
      <Route path="/mobile" element={<MobilePage />} />
      <Route path="/admin" element={<AdminPannel />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default App;
