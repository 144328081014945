import React, { useEffect, useState } from "react";
import { ObjectInterface } from "../interfaces";
import defaultStyle from "./C0601_SelectCardAdd.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";

interface SelectCardAddProps {
  styleObj?: ObjectInterface;
  cbFunc: () => void
}

const SelectCardAdd: React.FC<SelectCardAddProps> = ({
  styleObj,
  cbFunc,
}) => {
  /******* * Set styles custom / default  *************************/
  const [style, setStyle] = useState<ObjectInterface>({});

  useEffect(() => {
    if (styleObj) {
      setStyle(styleObj);
    } else {
      setStyle(defaultStyle);
    }
  }, [styleObj])
  /***************************************************************/

  function handleClick() {
    cbFunc();
  }
  return <button className={style.layout} onClick={handleClick}>
    <div className={style.plus__container}>
      +
    </div>
  </button>
}


export default SelectCardAdd