import React, { useEffect, useState } from "react";
import style from "./C0100_Spinner.module.css";
import imgSpinner from "../assets/images/spinner.gif";

interface SpinnerProps {
  message?: string;
  isHideTopMenu?: boolean;
  isOpen: boolean;
  onClose: () => void;
}
const Spinner: React.FC<SpinnerProps> = ({
  message,
  isHideTopMenu,
  isOpen,
  onClose,
}) => {

  /****** fade-in effect ************************/
  const [opacity, setOpacity] = useState(0);
  const transitionStyle = { transition: 'opacity 0.3s ease', opacity }
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpacity(1);
    }, 10);
  }, []);
  /*********************************************/

  /****** fade-out effect ************************/
  // const [nowOpen, setNowOpen] = useState<boolean>(isOpen);

  useEffect(() => {
    if (!isOpen) {
      setOpacity(0);
      setTimeout(() => {
        onClose();
      }, 300);
    }
  }, [isOpen, onClose]);

  const emergencyClose = () => {
    setOpacity(0);
    setTimeout(() => {
      onClose();
    }, 300);
  }
  /*********************************************/

  const layout = isHideTopMenu ? `${style.layout} ${style.hide__top}` : `${style.layout} ${style.show__top}`;
  // const layout = isHideTopMenu ? {style.layout, style.hide__top} : {style.layout, style.show__top};
  return (
    <div className={layout} style={transitionStyle}>
      <img className={style.img__spinner} src={imgSpinner} alt="loading.." />
      <p className={style.message}>{message ? message : "loading.."}</p>
      <div onMouseDown={emergencyClose} className={style.btn__emergency__close}>X</div>
    </div>
  );
};
export default Spinner;
