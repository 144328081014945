import React, { useEffect, useRef, useState } from "react";
import { DBMasterSite, ObjectInterface } from "../interfaces";
import style from "./L1110_SiteAdder.module.css"
import { FileUploader } from "react-drag-drop-files";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import usePostFetch from "../hooks/usePostFetch";
import { isEmpty } from "../utils/commonFunctions";
import ImageFileSelector from "../components/C0350_ImageFileSelector";


interface SiteAdderProps {
  styleObj?: ObjectInterface;
  isOpen: boolean;
  onClose: () => void;
  cbFunc: (data: DBMasterSite) => void;
}

const SiteAdder: React.FC<SiteAdderProps> = ({
  styleObj,
  isOpen,
  onClose,
  cbFunc,
}) => {

  /****** fade-in effect ************************/
  const [opacity, setOpacity] = useState(0);
  const transitionStyle = { transition: 'opacity 0.3s ease', opacity }
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setOpacity(1);
      }, 10);
    }
  }, []);
  /*********************************************/

  /****** fade-out effect ************************/
  // const [nowOpen, setNowOpen] = useState<boolean>(isOpen);

  useEffect(() => {
    if (!isOpen) {
      setOpacity(0);
      setTimeout(() => {
        onClose();
      }, 300);
    }
  }, [isOpen, onClose]);

  const closeThisWindow = (delay: number) => {
    setTimeout(() => {
      setOpacity(0);
    }, delay);

    setTimeout(() => {
      onClose();
    }, delay + 300);
  }
  /*********************************************/


  /****** ffile drag & drop ************************/
  const fileTypes = ["JPG", "PNG", "GIF"];

  const [file, setFile] = useState<string | null>(null);
  const [siteName, setSiteName] = useState<string>("")
  const [siteDescription, setSiteDescription] = useState<string>("")
  const [widthPhotoPreview, setWidthPhotoPreview] = useState<string>("0px")
  const [message, setMessage] = useState<string>("");
  const siteNameInputRef = useRef<HTMLInputElement>(null)
  const siteDescriptionInputRef = useRef<HTMLTextAreaElement>(null)

  const handleFileChange = (file: string) => {
    console.log(file);
    setFile(file);
    setWidthPhotoPreview("50%")
  };


  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string>("");
  const { data, loading, error, postFetchFunc } = usePostFetch();

  interface showMessageParams {
    message: string;
    showTime?: number | null;
  }
  function showMessage({ message, showTime }: showMessageParams) {
    setMessage(message);

    if (showTime) {
      setTimeout(() => {
        setMessage("");
      }, showTime);

    }
  }

  useEffect(() => {
    if (isSaving && loading === false) {
      if (!isEmpty(error)) {
        showMessage({ message: "Failed to save the site data", showTime: 2000 });

        console.log(error);
      } else {
        showMessage({ message: "Successfully saved" });
        closeThisWindow(1000);
      }
      setIsSaving(false);
    }
  }, [data, loading])

  const handleBtnClickSave = () => {
    if (isSaving) return;

    if (isEmpty(siteName)) {
      showMessage({ message: "The site title is mandatory field", showTime: 2000 });
      siteNameInputRef.current?.focus();
      return;
    }

    setIsSaving(true);

    const subUrl = "database/site/register"
    const content = {
      name: siteName,
      image_url: selectedImageUrl,
      description: siteDescription,
    }

    postFetchFunc(subUrl, content).finally(() => { })

  }

  const handleBtnClickCancel = () => {
    closeThisWindow(0);
  }

  function selectImageFile(dataUrl: string) {
    // console.log(dataUrl)
    setSelectedImageUrl(dataUrl)
  }

  return (
    <div className={style.layout} style={transitionStyle}>

      <div className={style.input__container}>
        <div className={style.header}>Register new site</div>
        <div className={style.data__row}>
          <label className={style.data__row__label} htmlFor="siteName">
            Site Title
          </label>
          <input
            className={style.data__row__input}
            id="siteName"
            value={siteName}
            ref={siteNameInputRef}
            onChange={(e) => setSiteName(e.target.value)}
          />
        </div>
        <div className={style.data__row}>
          <label className={style.data__row__label} htmlFor="siteDescription">
            Site Description
          </label>
          <textarea
            className={style.data__row__txtarea}
            id="siteDescription"
            value={siteDescription}
            ref={siteDescriptionInputRef}
            onChange={(e) => setSiteDescription(e.target.value)}
          />
        </div>
        <div className={style.data__row}>
          <label className={style.data__row__label}>
            Site Photo
          </label>
          <div className={style.data__row__fileloader}>
            <ImageFileSelector cbFunc={selectImageFile} />
          </div>
          <div className={style.message}>{message}</div>

          <div className={style.data__button}>
            <button className={`${style.buttons} ${style.btn__ok}`} onClick={handleBtnClickSave}>
              {isSaving ? <span><FontAwesomeIcon icon={faSpinner} spinPulse />  Saving..</span> : "Save"}
            </button>
            <button className={`${style.buttons} ${style.btn__cancel}`} onClick={handleBtnClickCancel}>Cancel</button>
          </div>
        </div>
      </div>

    </div>)
};


export default SiteAdder;