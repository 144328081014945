import React, { useState } from "react";
import style from "./L0150_AdminPannel.module.css"
import { ObjectInterface } from "../interfaces";
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import InboxIcon from '@mui/icons-material/Inbox';
import HeaderLv1 from "./L0210_HeaderLv1";
import UserManagement from "./L0160_UserManagement";


interface AdminPannelProps {
  styleObj?: ObjectInterface;
}

const AdminPannel: React.FC<AdminPannelProps> = (({
  styleObj,
}) => {

  const [currentMenu, setCurrentMenu] = useState<String>("users");

  const handleChangeMenu = (menu: String) => {
    setCurrentMenu(menu);
  }
  const renderContent = () => {
    switch (currentMenu) {
      case "users":
        return <UserManagement />;
      // case "sites":
      //   return <SiteManagement />;
      // default:
      //   return <SiteManagement />;
    }
  }

  return (
    <div className={style.layout}>
      <HeaderLv1 selectedMenu="Admin Pannel" />
      <div className={style.main}>
        <div className={style.menu}>
          <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleChangeMenu("users")}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="users" />
                </ListItemButton>
              </ListItem>
              {/* <ListItem disablePadding>
                <ListItemButton onClick={() => handleChangeMenu("sites")}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="sites" />
                </ListItemButton>
              </ListItem> */}
            </List>
          </Box>
        </div>
        <div className={style.content}>
          {renderContent()}
        </div>
      </div>
    </div>

  )
})

export default AdminPannel;