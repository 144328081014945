import React, { useState, useEffect, useRef } from 'react';
import { ObjectInterface } from '../interfaces';
import defaultStyle from './C0200_FloatingWindow.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faMinus, faThumbtack } from '@fortawesome/free-solid-svg-icons';

interface FloatingWindowProps {
  styleObj?: ObjectInterface;
  contentObj?: JSX.Element;
  isPinned: boolean;
  title?: string;
}
const FloatingWindow: React.FC<FloatingWindowProps> = ({
  styleObj,
  contentObj,
  isPinned,
  title,
}) => {

  /************************
 * Set styles custom / default
 */
  const [style, setStyle] = useState<ObjectInterface>({});

  useEffect(() => {
    if (styleObj) {
      setStyle(styleObj);
    } else {
      setStyle(defaultStyle);
    }

  }, [styleObj])
  /***************************/




  /*********************
   * handler for window panning
   */

  const floatingWindowRef = useRef<HTMLDivElement>(null);
  const [windowHeight, setWindowHeight] = useState<React.CSSProperties>({ minHeight: "25px" });

  const handleWindowMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    const subWindow = floatingWindowRef.current;
    if (!subWindow) return;

    const offsetX = subWindow.offsetLeft;
    const offsetY = subWindow.offsetTop;
    const mouseX = e.clientX;
    const mouseY = e.clientY;

    const mouseMoveHandler = function (e: MouseEvent) {
      const dx = e.clientX - mouseX;
      const dy = e.clientY - mouseY;

      subWindow.style.top = `${offsetY + dy}px`;
      subWindow.style.left = `${offsetX + dx}px`;
    };

    const mouseUpHandler = function () {
      subWindow.removeEventListener('mousemove', mouseMoveHandler);
      subWindow.removeEventListener('mouseup', mouseUpHandler);
    };

    subWindow.addEventListener('mousemove', mouseMoveHandler);
    subWindow.addEventListener('mouseup', mouseUpHandler);
  };

  /*****************
 * handler for min, maximize window
 */

  const [isClosed, setIsClosed] = useState<boolean>(false);
  const [savedHeight, setSavedHeight] = useState<React.CSSProperties>({ height: "30px" });

  const handleSizeBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {

    const floatingWindow = floatingWindowRef.current;

    if (!floatingWindow) return;

    if (isClosed) {
      setWindowHeight(savedHeight);
    } else {
      setSavedHeight({ height: `${floatingWindow.style.height}` });
      setWindowHeight(() => {
        return { height: "25px" };
      });
    }

    setIsClosed(!isClosed);
  }
  /******************************/

  /*****************
* handler for min, maximize window
*/

  const [isThisPinned, setIsThisPinned] = useState<boolean>(isPinned);

  const handlePinBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsThisPinned(!isThisPinned);
  }

  /******************************/

  // const positionLayout: React.CSSProperties = {}
  const positionLayout: string = isThisPinned ? style.isPinned : style.isNotPinned

  return (
    <div className={`${style.layout} ${positionLayout}`} ref={floatingWindowRef} style={{ ...windowHeight }}>
      <div className={style.topbar}
        onMouseDown={handleWindowMouseDown}
      >
        {isPinned &&
          <button
            className={style.btn__pinned}
            onClick={handlePinBtnClick}
          ><FontAwesomeIcon icon={faThumbtack} />
          </button>
        }
        <pre>{title}</pre>
        <button
          className={style.btn__minimize}
          onClick={handleSizeBtnClick}
          disabled={isThisPinned ? true : false}>
          <FontAwesomeIcon icon={faMinus} />
        </button>


      </div>
      <div className={style.content}>
        {contentObj ? contentObj : "content"}
      </div>

    </div>
  );
};


export default FloatingWindow;