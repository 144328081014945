import React from "react";
import style from './C0360_CombinedViewer.module.css'


interface CombinedViewerProps {
    frontView?: JSX.Element;
    sensorView?: JSX.Element;
    thermalView?: JSX.Element;
    panoramaView?: JSX.Element;
}

const CombinedViewer: React.FC<CombinedViewerProps> = ({
    frontView,
    sensorView,
    thermalView,
    panoramaView,
}) => {

    return (
        <div className={style.layout}>
            <div className={style.toparea}>
                <div>{frontView ? frontView : ""}</div>
                <div>{thermalView ? thermalView : ""}</div>
                <div>{sensorView ? sensorView : ""}</div>
            </div>
            <div className={style.bottomarea}>
                {panoramaView ? panoramaView : ""}
            </div>
        </div>
    );
}

export default CombinedViewer;