import React, { useCallback, useEffect, useState, } from "react";
import { useGlobalContext } from "../context/GlobalContext";
import { FilteredTaskResultForNodes, ObjectInterface, FetchMapFgBg, GeoInfo } from "../interfaces";
import defaultStyle from "./L4220_ViewerContentList.module.css";
import Spinner from "../components/C0100_Spinner";
import { compareItemsInTwoArray, isEmpty } from "../utils/commonFunctions";
import TaskImageListRow from "../components/C0500_TaskImageListRow";
import KeyMap from "../components/C0310_KeyMap";
import FloatingWindow from "../components/C0200_FloatingWindow";

interface ViewerContentListProps {
  styleUrl?: string;
  filteredTaskResultForNodesForThisView: FilteredTaskResultForNodes;
  mapData: FetchMapFgBg;
  currentGeoInfo: GeoInfo | null | undefined;
}


const ViewerContentList: React.FC<ViewerContentListProps> = ({
  styleUrl,
  filteredTaskResultForNodesForThisView,
  mapData,
  currentGeoInfo }) => {


  /************************
* Set styles custom / default
*************************/
  const [style, setStyle] = useState<ObjectInterface>({});

  useEffect(() => {
    let isError = true;

    if (styleUrl) {
      import(styleUrl)
        .then((cssModule: ObjectInterface) => {
          setStyle(cssModule);
          isError = false;
        })
        .catch((error) => { console.error("error in importing custom cssfile: ", error) })
    }

    if (isError) {
      setStyle(defaultStyle);
    }
  }, [styleUrl])
  /***************************/
  const {
    gTaskResults,
    gSelectedSpaceDb,
    gSelectedPatrolUTCTimeStampsRange,
    gSelectedActionItems,
    gSelectedGroupByItems,
    gSelectedFloorName,
    gSelectedMapName,
    gFilteredTaskResultForNodes,
    gIdsForCheckedTaskResult,
    updateGlobalContext,
  } = useGlobalContext();


  const [taskIdOnMouseOver, setTaskIdOnMouseOver] = useState<string>("");
  const taskImageClickHandler = useCallback((taskId: string) => {
    // For multi Select
    // if (gIdsForCheckedTaskResult.includes(taskId)) {
    //   updateGlobalContext({ gIdsForCheckedTaskResult: [...gIdsForCheckedTaskResult.filter((theId => theId !== taskId))] });
    // } else {
    //   updateGlobalContext({ gIdsForCheckedTaskResult: [...gIdsForCheckedTaskResult, taskId] });
    // }


    console.log("Before gIdsForCheckedTaskResult:", gIdsForCheckedTaskResult);
    console.log("  - taskId: ", taskId);

    if (gIdsForCheckedTaskResult.includes(taskId)) {
      updateGlobalContext({ gIdsForCheckedTaskResult: [] });
    } else {
      updateGlobalContext({ gIdsForCheckedTaskResult: [taskId] });
    }

  }, [gIdsForCheckedTaskResult, updateGlobalContext])

  useEffect(() => {
    console.log("DEBUG gIdsForCheckedTaskResult:", gIdsForCheckedTaskResult);
  }, [gIdsForCheckedTaskResult])

  const taskImageMouseOverHandler = useCallback((taskId: string) => {
    setTaskIdOnMouseOver(taskId);

    // console.log("[DEBUG!!!]", taskId)
  }, [])


  const [isDetailViewLoaded, setIsDetailViewLoaded] = useState<boolean>(false);
  const handleDetailViewCloseButton = () => {
    setIsDetailViewLoaded(false);
  }

  const openDetailViewer = () => {
    setIsDetailViewLoaded(true);
  }

  const keyMapObject: JSX.Element = (
    <KeyMap mapData={mapData} currentGeoInfo={currentGeoInfo} taskIdOnMouseOver={taskIdOnMouseOver} />
  )

  return <div className={style.layout}>

    <FloatingWindow
      title="Key Map"
      isPinned={true}
      contentObj={keyMapObject}
    />

    <div className={style.dummy__slot}> </div>
    <div className={style.list__container}>
      {!isEmpty(filteredTaskResultForNodesForThisView) && Object.entries(filteredTaskResultForNodesForThisView).map(([id, theTaskResultForNode]) => {
        // console.log(id);
        // return <></>
        return (
          <TaskImageListRow
            key={id}
            onClickHandler={taskImageClickHandler}
            onMouseOverHandler={taskImageMouseOverHandler}
            taskResult={theTaskResultForNode}
            isChecked={gIdsForCheckedTaskResult.includes(theTaskResultForNode.id)} />
        )
      })
      }</div>
    <div className={style.dummy__slot}> </div>
  </div>;

};

export default ViewerContentList;
