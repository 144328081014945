import React, { useEffect, useRef, useState } from "react";
import { ObjectInterface, PanoramaMotion } from "../interfaces";
import { Pannellum, PannellumVideo } from "pannellum-react";
import defaultStyle from "./C0330_PanoramaViewer.module.css";
import { INIT_PANORAMA_MOTION } from "../utils/commonConstants";
import { renderingConsoleLog } from "../utils/commonFunctions";
import downloadingImg from "../assets/images/downloading.gif"
import gslogograyImg from "../assets/images/gslogo_gray.png"

interface PanoramaViewerProps {
  styleObj?: ObjectInterface;
  panoramaUrl: string;
  previewUrl?: string | null | undefined;
  updatePanoramaMotion?: (data: PanoramaMotion) => void;
  initPanoramaMotion?: PanoramaMotion;
  title?: string;
  subtitle?: string;
}



const PanoramaViewer: React.FC<PanoramaViewerProps> = (({
  styleObj,
  panoramaUrl,
  previewUrl,
  updatePanoramaMotion,
  initPanoramaMotion,
  title,
  subtitle
}) => {

  /***** Performance Checker ***************/
  const renderCounter = useRef<number>(0);
  renderingConsoleLog({ componentName: "Panorama", cnt: renderCounter.current += 1 })



  useEffect(() => {
    console.log("\n~~~~~~~~~~~~~~~")
    console.log("Panorama Viewer is started to render")
    console.log("~~~~~~~~~~~~~~~\n")
  }, []);


  const [panoramaImageBlob, setPanoramaImageBlob] = useState<String>("");

  useEffect(() => {
    fetch(panoramaUrl)
      .then(response => {
        if (response.ok) {
          return response.blob()
        } else {
          throw new Error()
        }
      })
      .then(blob => {
        const imageBlob = URL.createObjectURL(blob);
        setPanoramaImageBlob(imageBlob);

        return () => {
          URL.revokeObjectURL(imageBlob);
        }
      })
      .catch(e => {
        console.log("Invalid parama url:", panoramaUrl);
        setPanoramaImageBlob("error");

      })

  }, [panoramaUrl])
  /****************************
 * Set styles custom / default
 ***********************************/
  const [style, setStyle] = useState<ObjectInterface>({});

  useEffect(() => {
    // setThisPanoramaUrl(panoramaUrl);
    // setThisPreviewUrl(previewUrl ? previewUrl : "");
  }, [panoramaUrl, previewUrl]);

  useEffect(() => {
    if (styleObj) {
      setStyle(styleObj);
    } else {
      setStyle(defaultStyle);
    }

  }, [styleObj])
  /***************************/


  /****************************
 * Parameters/States
 ***********************************/
  const panoramaRef = useRef<any>(null);
  const [initMotionSetting, setInitMotionSetting] = useState<PanoramaMotion>(initPanoramaMotion ? initPanoramaMotion : INIT_PANORAMA_MOTION)
  const [panoramaMotion, setPanoramaMotion] = useState<PanoramaMotion>({ yawDeg: 0, pitchDeg: 0, hfovDeg: 100 });

  useEffect(() => {
    if (initPanoramaMotion) {
      setPanoramaMotion(initPanoramaMotion);
    }
  }, [initPanoramaMotion]);
  /***************************/


  /************** event hanlder ***********/
  const [isMouseDragging, setIsMouseDragging] = useState<boolean>(false);

  const updateMotion = () => {
    const panorama = panoramaRef.current;

    if (panorama) {
      const pitch = panorama.getViewer().getPitch();
      const yaw = panorama.getViewer().getYaw();
      const hfov = panorama.getViewer().getHfov();

      const updatedMotion: PanoramaMotion = { yawDeg: yaw, pitchDeg: pitch, hfovDeg: hfov };

      updatePanoramaMotion?.(updatedMotion);
      setPanoramaMotion(updatedMotion);
      console.log("DEBUG Panorama Motion Updated", updatedMotion);

    }
  }

  const handleMouseDown = () => {
    setIsMouseDragging(true);
  }

  const handleMouseMove = () => {
    if (isMouseDragging) updateMotion();
  }

  const handleMouseUp = () => {
    setIsMouseDragging(false);
  }

  const handleWheel = () => {
    updateMotion();
  }

  return (
    <div className={style.layout}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onWheel={handleWheel} >
      {(panoramaImageBlob === "") &&
        <div className={style.loading__container}>
          <img className={style.loading__img} src={downloadingImg} />
          <label className="">loading the 360 image.. (It may take several seconds)</label>
        </div>
      }
      {(panoramaImageBlob === "error") &&
        <div className={style.loading__container}>
          {/* <img className={style.loading__img} src={gslogograyImg} /> */}
          <label>no panorama image</label>
        </div>
      }
      {(panoramaImageBlob !== "" && panoramaImageBlob !== "error") &&
        <Pannellum
          ref={panoramaRef}
          width="100%"
          height="100%"
          image={panoramaImageBlob}
          pitch={initMotionSetting.pitchDeg}
          yaw={initMotionSetting.yawDeg}
          // hfov={initMotionSetting.hfovDeg}
          hfov={135}
          // northOffset={50}
          // type = "multires"
          autoLoad
          compass={true}
          showControls={true}
          author={subtitle ? subtitle : ""}
          title={title ? title : ""}
          orientationOnByDefault={false}
          draggable
          keyboardZoom
          mouseZoom
          preview={previewUrl}
          // previewAuthor=""
          // previewTitle=""
          showFullscreenCtrl={true}
          showZoomCtrl
        // onLoad={handlePanoramaLoaded}
        // onScenechange={handleScreenChange}
        // onScenechangefadedone={handlePanoramaLoaded}
        // onError={(err: any) => { console.log("Error", err); }}
        // onErrorcleared={() => { console.log("Error Cleared"); }}
        // onMousedown={handleUpdateConfig}
        // onMouseup={handleUpdateConfig}
        // onTouchstart={(evt: any) => { console.log("Touch Start", evt); }}
        // onTouchend={(evt: any) => { console.log("Touch End", evt); }}
        // hotspotDebug={false}
        ></Pannellum>
      }

    </div >
  )
})

export default PanoramaViewer;