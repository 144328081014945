/// centersearch.tsx

import React, { useCallback, useEffect, useMemo, useState, useRef, memo, } from "react";
import useGetFetch from "../hooks/useGetFetch";
import ListSelector from "../components/C0420_ListSelector";
import defaultStyle from "./L4100_ViewerMenu.module.css";
import ToggleButtonGroup from "../components/C0440_ToggleButtonGroup";
import { useGlobalContext } from "../context/GlobalContext";
import { SpaceDB, ObjectInterface, TaskResultInterface, ActionTypeInterface, FilteredTaskResultForNodes, TaskResultForNode } from "../interfaces";
import { convertArrayToObject, convertUTCsDateTimeToLocal, isEmpty, renderingConsoleLog } from "../utils/commonFunctions";
import laySelectorStyle from "./L4100_myC0440_ToggleButtonGroup.module.css"
import DateRangeSelector from "../components/C0435_DateRangeSelector";
import { ACTION_ITEMS } from "../utils/commonConstants";
import useGetFetch2 from "../hooks/useGetFetch2";
import { text } from "stream/consumers";

interface ViewerMenuProps {
  styleUrl?: string;
}


interface FetchSpaceDB {
  result: boolean;
  data: SpaceDB;
}

const ViewerMenu: React.FC<ViewerMenuProps> = ({ styleUrl }) => {
  // Performance Checker
  const renderCounter = useRef<number>(0);
  renderingConsoleLog({ componentName: "ViewerMenu", cnt: renderCounter.current += 1 })

  // const [selectedMap, setSelectedMap] = useState<string>("");
  // const [isLayerSelectorLoading, setIsLayerSelectorLoading] = useState(true);

  const {
    gSelectedSite,
    gFloorNameToMapName,
    gSelectedSpaceDb,
    gSelectedPatrolDb,
    gSelectedFloorName,
    gSelectedMapName,
    updateGlobalContext,
    gSelectedPatrolUTCTimeStampsRange,
  } = useGlobalContext();


  /************************
 * Set styles custom / default
 *************************/
  const [style, setStyle] = useState<ObjectInterface>({});

  useEffect(() => {
    let isError = true;

    if (styleUrl) {
      import(styleUrl)
        .then((cssModule: ObjectInterface) => {
          setStyle(cssModule);
          isError = false;
        })
        .catch((error) => { console.error("error in importing custom cssfile: ", error) })
    }

    if (isError) {
      setStyle(defaultStyle);
    }
  }, [styleUrl])
  /***************************/

  useEffect(() => {
    const theActionItems = Object.keys(ACTION_ITEMS);
    updateGlobalContext({ gActionItems: theActionItems })
    updateGlobalContext({ gSelectedActionItems: theActionItems });
  }, []);




  /**** General Functions for Menu Update ****************/
  // const [spaceDb, setSpaceDb] = useState<String>(gSelectedSpaceDb);
  // const [patrolDb, setPatrolDb] = useState<String>(gSelectedPatrolDb);
  // const [mapName, setMapName] = useState<String>(gSelectedMapName);
  // const [layerName, setLayerName] = useState<String>(gSelectedFloorName);

  const [initDateRange, setInitDateRange] = useState<{ from: string, to: string }>({ from: "", to: "" });
  const [baseFilter, setBaseFilter] = useState<string>("");


  /*****************************************************
   * fetching: task result ****************************
   *****************************************************/
  const {
    data: data_task_result,
    loading: loading_task_result,
    error: error_task_result,
    getFetchFunc: task_result_getFetchFunc
  } = useGetFetch2<{ result: boolean; data: TaskResultInterface[] }>();

  useEffect(() => {
    if (gSelectedSpaceDb && gSelectedMapName) {
      const subUrl = `database/task/get_task_result?site_folder_name=${gSelectedSite?.subfolder_title}&space_title=${gSelectedSpaceDb}&map_title=${gSelectedMapName}&patrol_title=${gSelectedPatrolDb}`;
      task_result_getFetchFunc(subUrl).finally(() => { });
    }
  }, [gSelectedSpaceDb, gSelectedMapName])

  const [taskResultData, setTaskResultData] = useState<TaskResultInterface[]>([]);

  // useEffect(() => {
  //   if (loading_task_result) {
  //     updateGlobalContext({ "gMapLoading": false });
  //     return;
  //   }

  //   if (data_task_result || error_task_result) {
  //     updateGlobalContext({ "gMapLoading": true });
  //     return;
  //   }

  // }, [data_task_result, loading_task_result, error_task_result])

  useEffect(() => {
    if (error_task_result !== null) {
      console.error("Error duing fetching date\n -error msg:", error_task_result);
      return;
    }

    console.log("DEBUG loading_task_result: ", loading_task_result);
    console.log("DEBUG data_task_result: ", data_task_result);
    console.log("DEBUG data_task_result.result: ", data_task_result ? data_task_result.result : "null");

    if (!loading_task_result && data_task_result && data_task_result.result) {

      // console.log("DEBUG data_task_result", data_task_result);
      const taskResults = data_task_result.data;
      // console.log("XXX taskResults XXXXX:\n ", taskResults);
      setTaskResultData(taskResults);
      updateGlobalContext({ gTaskResults: taskResults });
      createTaskResultForNodes(taskResults);
    }
  }, [data_task_result, error_task_result, updateGlobalContext]);






  // 3. updator after fetch handler
  const createTaskResultForNodes = useCallback((taskResults: TaskResultInterface[]) => {
    const theTaskResultForNodes: FilteredTaskResultForNodes = {};

    // console.log("DEBug taskResults!!!!\n", taskResults);

    taskResults.forEach((theTaskResult) => {

      // grouping key to list up in the contentlist component
      const groupingKey = `${theTaskResult.space_db_title}+${theTaskResult.patrol_title}+${theTaskResult.layer_title}+${theTaskResult.node_number}`;

      if (!Object.keys(theTaskResultForNodes).includes(groupingKey)) {
        const theTaskResultForNode: TaskResultForNode = {
          id: groupingKey,
          map_title: theTaskResult.map_title,
          record: {},
          action_type_list: [],
          patrol_utc_timestamp_list: [],
          filter: {
            space_db_title: theTaskResult.space_db_title,
            patrol_title: theTaskResult.patrol_title,
            layer_title: theTaskResult.layer_title,
            node_number: theTaskResult.node_number
          }
        }
        theTaskResultForNodes[groupingKey] = theTaskResultForNode
      }


      // if the timestamp is new
      const thisRecord = theTaskResultForNodes[groupingKey].record;
      if (!Object.keys(thisRecord).includes(theTaskResult.patrol_utc_time_stamp)) {
        thisRecord[theTaskResult.patrol_utc_time_stamp] = {
          thumbnail_link: "",
          results: {},
          geo_info: { x: 0, y: 0, z: 0, rot: 0 }
        }
      }

      const thisTimeStamp = theTaskResultForNodes[groupingKey].record[theTaskResult.patrol_utc_time_stamp];
      // thumbnail update for the timestamp
      if (theTaskResult.action_type === "CAM_COMBINED_IMAGE") {
        const newThumbnailLink = theTaskResult.action_data.thumbnail as string;
        thisTimeStamp.thumbnail_link = newThumbnailLink;

        const newGeoInfo = theTaskResult.geo_info;
        thisTimeStamp.geo_info = { ...newGeoInfo };

        if (!theTaskResultForNodes[groupingKey].patrol_utc_timestamp_list.includes(theTaskResult.patrol_utc_time_stamp)) {
          theTaskResultForNodes[groupingKey].patrol_utc_timestamp_list.push(theTaskResult.patrol_utc_time_stamp);
        };
      }

      const thisResult = thisTimeStamp.results ? thisTimeStamp.results : {};

      thisResult[theTaskResult.action_type] = {
        action_utc_time_stamp_start: (theTaskResult.action_utc_time_stamp_start ? theTaskResult.action_utc_time_stamp_start : ""),
        data: theTaskResult.action_data,
        id: theTaskResult.id
      }

      // thisResult[theTaskResult.action_type] = theTaskResult.id

      if (!theTaskResultForNodes[groupingKey].action_type_list.includes(theTaskResult.action_type)) {
        theTaskResultForNodes[groupingKey].action_type_list.push(theTaskResult.action_type);
      }
    })

    // console.log("[DEBUG!!!]\n", JSON.stringify(theTaskResultForNodes, null, 2));
    // console.log("[DEBUG!!!]\n", theTaskResultForNodes);

    updateGlobalContext({ "gFilteredTaskResultForNodes": theTaskResultForNodes });
  }, [updateGlobalContext]);


  // // 2. fetch handeler
  // useEffect(() => {
  //   if (error_task_result !== null) {
  //     console.error("Error duing fetching date\n -error msg:", error_task_result);
  //     return;
  //   }
  //   if (!loading_task_result && data_task_result) {
  //     console.log("DEBUG data_task_result", data_task_result);
  //     const taskResults = data_task_result.data;

  //     updateGlobalContext({ gTaskResults: taskResults });
  //     createTaskResultForNodes(taskResults);
  //   }
  // }, [data_task_result, loading_task_result, updateGlobalContext, createTaskResultForNodes]);





  /*****************************************************
   * functions for updating space db
   *****************************************************/
  const [spaceDbSubUrl, setSpaceDbSubUrl] = useState<string>(`database/space/getlist_nameonly?site_folder_name=${gSelectedSite?.subfolder_title}`);

  useEffect(() => {
    setSpaceDbSubUrl(`database/space/getlist_nameonly?site_folder_name=${gSelectedSite?.subfolder_title}`)
  }, [gSelectedSite])




  const {
    data: spacedb_data,
    loading: spacedb_loading,
    error: spacedb_error,
    getFetchFunc: spacedb_getFetchFunc,
  } = useGetFetch2<FetchSpaceDB>();

  useEffect(() => {
    if (gSelectedSpaceDb) {
      const subUrl = `database/space/load?site_folder_name=${gSelectedSite?.subfolder_title}&space=${gSelectedSpaceDb}`
      spacedb_getFetchFunc(subUrl).finally(() => { });
    }

  }, [gSelectedSpaceDb]);

  const [mapNameToMapAlias, setMapNameToMapAlias] = useState<ObjectInterface>({})

  useEffect(() => {
    if (!spacedb_loading && spacedb_data) {
      console.log("spacedb_data:", spacedb_data);
      if (spacedb_data.result) {
        const data = spacedb_data.data;
        const links = data.link;

        const thisMapNames: string[] = [];
        const thisMapNameToMapAlias: ObjectInterface = {};

        for (let i = links.length - 1; i >= 0; i--) {
          const link = links[i]; // from the utmost bottom layer in the links

          const map = link.bottom_layer.map_name;
          const layer_name = link.bottom_layer.layer_name;
          if (!thisMapNames.includes(map)) {
            thisMapNames.push(map);
          }

          thisMapNameToMapAlias[map] = layer_name;

          // if i=== 0 and top layer is not defined, it means the space has only one map.
          if (i === 0 && link.top_layer !== undefined) {
            const to_map = link.top_layer.map_name;
            const to_layer_name = link.top_layer.layer_name;

            if (!thisMapNames.includes(to_map)) {
              thisMapNames.push(to_map);
            }

            thisMapNameToMapAlias[to_map] = to_layer_name;

          }
        }

        setMapNameToMapAlias(thisMapNameToMapAlias);
        // updateGlobalContext({ gFloorNameToMapName: thisLayerNameToMapName });
        updateGlobalContext({ gMapNames: thisMapNames });

        // console.log("thisLayerNameToMapName", thisLayerNameToMapName);
      }
    }
  }, [spacedb_data, spacedb_loading]);


  /***************************************
   * toggle button handlers
   ****************************************/

  // space db (1/1)
  const handleSpaceDbUpdate = useCallback((thisSpaceDb: string) => {
    if (thisSpaceDb !== gSelectedSpaceDb) {
      updateGlobalContext({
        gSelectedSpaceDb: thisSpaceDb,
        gSelectedMapName: "",
        gSelectedPatrolDb: "",
        gSelectedFloorName: "",
        gTaskResults: [],
        gIdsForCheckedTaskResult: []
      })
    }
    setBaseFilter("");
  }, [gSelectedSpaceDb]);


  // base filter (1/3)
  const menusForBaseFilter = useMemo(() => {
    return {
      "MAP": "도면(Map) 중심",
      "PATROLDB": "층(Floor) 중심",
    }
  }, []);


  // base filter (3/3)
  const handlerBaseFilter = useCallback((key: string, value: string) => {
    if (baseFilter !== key) {
      setBaseFilter(key);

      updateGlobalContext({
        gSelectedMapName: "",
        gSelectedPatrolDb: "",
        gSelectedFloorName: "",
        gIdsForCheckedTaskResult: [],
        gFilteredTaskResultForNodes: {},
      })
    }

  }, [baseFilter]);



  // mapdb (1/2)
  const [floorNames, setFloorNames] = useState<ObjectInterface>({}); // updated during space db fetching
  // mapdb (2/2)
  const handlerSelectMapToggleButton = useCallback((selectedMapName: string, dummy: string) => {
    console.log("selectedMapName:", selectedMapName);
    console.log("gSelectedMapName:", gSelectedMapName);
    // console.log("gFloorNameToMapName:\n", gFloorNameToMapName);
    if (selectedMapName !== "" && (gSelectedMapName !== selectedMapName)) {
      // console.log("gMapLoading: true");
      // console.log("gMapLoading: true");
      // console.log("gMapLoading: true");

      updateGlobalContext({
        gMapLoading: true
      })
    }

    updateGlobalContext({
      gSelectedMapName: selectedMapName,
      gSelectedPatrolDb: "",
      gSelectedFloorName: "",
      gTaskResults: [],
      gIdsForCheckedTaskResult: []
    })

  }, [gSelectedMapName]);



  // patrol db (1/3)
  const [patrolDbSubUrl, setPatrolDbSubUrl] = useState<string>("");
  // patrol db (2/3)
  useEffect(() => {
    if (gSelectedSpaceDb !== "") {
      setPatrolDbSubUrl(
        `database/patrol/get_db_nameonly?site_folder_name=${gSelectedSite?.subfolder_title}&space_db_title=${gSelectedSpaceDb}`
      );
    }
  }, [gSelectedSpaceDb]);

  // patrol db (3/3)
  const handlePatrolDBUpdate = useCallback((thisPatrolDb: string) => {
    if (thisPatrolDb != gSelectedPatrolDb) {
      updateGlobalContext({
        // gSelectedSpaceDb,
        gSelectedMapName: "",
        gSelectedPatrolDb: thisPatrolDb,
        gSelectedFloorName: "",
        gTaskResults: [],
        gIdsForCheckedTaskResult: []
      })
    }
  }, [gSelectedPatrolDb]);



  // layer name (1/1)
  const handlerSelectFloorToggleButton = useCallback((selectedFloorName: string) => {
    if (!gFloorNameToMapName) return;

    if (selectedFloorName !== gSelectedFloorName) {
      const selectedMapName = selectedFloorName === "" ? "" : gFloorNameToMapName[selectedFloorName]

      console.log("selectedMapName:", selectedMapName)

      if (selectedFloorName !== "" && selectedMapName !== gSelectedMapName) {
        console.log("gMapLoading: true");
        console.log("gMapLoading: true");
        console.log("gMapLoading: true");

        updateGlobalContext({
          gMapLoading: true
        })
      }

      updateGlobalContext({
        // gSelectedSpaceDb,
        gSelectedMapName: selectedMapName,
        // gSelectedPatrolDb,
        gSelectedFloorName: selectedFloorName,
        gIdsForCheckedTaskResult: []
      })
    }

  }, [gSelectedFloorName, gFloorNameToMapName]);


  // patrol floor and map key pair
  const {
    data: data_patrol_floor_map,
    loading: loading_patrol_floor_map,
    error: error_patrol_floor_map,
    getFetchFunc: getFetchFunc_patrol_floor_map
  } = useGetFetch2<{ result: boolean; data: ObjectInterface }>();

  useEffect(() => {
    if (gSelectedSite && gSelectedSpaceDb && gSelectedPatrolDb) {
      getFetchFunc_patrol_floor_map(`database/patrol/floor_map_pair?site_folder_name=${gSelectedSite?.subfolder_title}&space_db_title=${gSelectedSpaceDb}&patrol_db_title=${gSelectedPatrolDb}`)
    }
  }, [gSelectedPatrolDb])

  const [floorFilterInput, setFloorFilterInput] = useState<string>("계단, 복귀");

  useEffect(() => {
    if (data_patrol_floor_map && data_patrol_floor_map.result) {
      console.log("data_patrol_floor_map:\n", data_patrol_floor_map);

      const theFloorNames: ObjectInterface = {} //convertArrayToObject<string>(Object.keys(data_patrol_floor_map.data), false);
      // const filteredFloorNames = {}

      Object.keys(data_patrol_floor_map.data).forEach(floor => {
        let willInclude = true;
        floorFilterInput.split(",").forEach(filter => {
          if (floor.includes(filter)) {
            willInclude = false;
            return
          }
        })

        if (willInclude) {
          theFloorNames[floor] = floor;
        }
      })

      console.log("theFloorNames", theFloorNames);

      setFloorNames(theFloorNames);
      updateGlobalContext({ gFloorNameToMapName: data_patrol_floor_map.data })

    }
  }, [data_patrol_floor_map, floorFilterInput])

  /*****************************************************
   * function for date range selector
   **************************************************/


  useEffect(() => {

    console.log(" XXX initDateRange: ", initDateRange);
    console.log(" XXX gSelectedPatrolUTCTimeStampsRange", gSelectedPatrolUTCTimeStampsRange);

    // if (initDateRange.from !== "") return;

    if (gSelectedPatrolUTCTimeStampsRange && gSelectedPatrolUTCTimeStampsRange.from != "" && gSelectedPatrolUTCTimeStampsRange.to != "") {
      const dateFrom = new Date(gSelectedPatrolUTCTimeStampsRange.from);
      const dateTo = new Date(gSelectedPatrolUTCTimeStampsRange.to);

      const yearFrom = dateFrom.getFullYear();
      const monthFrom = String(dateFrom.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
      const dayFrom = String(dateFrom.getDate()).padStart(2, '0');
      const localDateFromString = `${yearFrom}-${monthFrom}-${dayFrom}`;


      const yearTo = dateTo.getFullYear();
      const monthTo = String(dateTo.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
      const dayTo = String(dateTo.getDate()).padStart(2, '0');
      const localDateToString = `${yearTo}-${monthTo}-${dayTo}`

      setInitDateRange({
        from: localDateFromString,
        to: localDateToString
      });

      console.log(" YYY initDateRange: ", localDateFromString, localDateToString);
      console.log(" YYY gSelectedPatrolUTCTimeStampsRange", gSelectedPatrolUTCTimeStampsRange);

      return;
    }


    const today = new Date()
    const oneMonthBack = new Date(today)
    oneMonthBack.setMonth(today.getMonth() - 1);

    const yearFrom = oneMonthBack.getFullYear();
    const monthFrom = String(oneMonthBack.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const dayFrom = String(oneMonthBack.getDate()).padStart(2, '0');
    const localDateFromString = `${yearFrom}-${monthFrom}-${dayFrom}`;

    const yearTo = today.getFullYear();
    const monthTo = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const dayTo = String(today.getDate()).padStart(2, '0');
    const localDateToString = `${yearTo}-${monthTo}-${dayTo}`

    setInitDateRange({
      from: localDateFromString,
      to: localDateToString
    });



    // setInitDateRange({
    //   from: alreadySelectedRangeFrom ? alreadySelectedRangeFrom : oneMonthBackIsoString,
    //   to: alreadySelectedRangeTo ? alreadySelectedRangeTo : todayIsoString
    // });

    updateGlobalContext({ "gSelectedPatrolUTCTimeStampsRange": { from: oneMonthBack.toISOString(), to: today.toISOString() } });

    console.log(" ZZZ initDateRange: ", localDateFromString, localDateToString);
    console.log(" ZZZ gSelectedPatrolUTCTimeStampsRange", oneMonthBack.toISOString(), today.toISOString());


  }, [gSelectedPatrolUTCTimeStampsRange]);


  const handleDateRangeSelector = (fromDate?: Date, toDate?: Date) => {

    console.log("1111from: ", fromDate)
    console.log("1111to: ", toDate)

    if (fromDate && toDate) {
      console.log("from: ", fromDate.toISOString())
      console.log("to: ", toDate.toISOString())

      updateGlobalContext({ "gSelectedPatrolUTCTimeStampsRange": { from: fromDate.toISOString(), to: toDate.toISOString() } });
    }
  }


  useEffect(() => {

  }, [gSelectedPatrolDb])


  return (
    <div className={style.layout}>
      <div className={style.txt__datafilter}><pre>Data Filter</pre>
      </div>
      <DateRangeSelector
        title="촬영 일자"
        fromInitYYYYMMDD={initDateRange.from}
        toInitYYYYMMDD={initDateRange.to}
        cbFunc={handleDateRangeSelector}
      />
      <ListSelector
        headerTitle="건물이름"
        title="space db"
        itemsFetchUrl={spaceDbSubUrl}
        cbFunc={handleSpaceDbUpdate}
        defaultKey={gSelectedSpaceDb}
      />

      {!isEmpty(gSelectedSpaceDb) &&
        <ToggleButtonGroup
          headerTitle="검색기준: 도면 또는 층"
          itemLoading={false}
          items={menusForBaseFilter}
          cbFunc={handlerBaseFilter}
          defaultKey={baseFilter}
        />}


      {/*** In case the user select map */}
      {baseFilter === "MAP" && mapNameToMapAlias &&
        <ToggleButtonGroup
          headerTitle="도면 선택"
          itemLoading={false}
          items={mapNameToMapAlias}
          cbFunc={handlerSelectMapToggleButton}
          styleObj={laySelectorStyle}
          willDisplayKey={false}
          defaultKey=""
        />}

      {/*** In case the user select patrol db */}
      {baseFilter === "PATROLDB" && !isEmpty(gSelectedSpaceDb) &&
        <ListSelector
          headerTitle="순찰루틴(Patrol DB) 선택"
          title="patrol db"
          itemsFetchUrl={patrolDbSubUrl}
          cbFunc={handlePatrolDBUpdate}
          defaultKey={gSelectedPatrolDb}
        />
      }

      {baseFilter === "PATROLDB" && !isEmpty(gSelectedPatrolDb) && floorNames &&
        <ToggleButtonGroup
          headerTitle="층(Floor) 선택"
          itemLoading={false}
          items={floorNames}
          cbFunc={handlerSelectFloorToggleButton}
          willDisplayKey={true}
          styleObj={laySelectorStyle}
          defaultKey=""
        />}
      {baseFilter === "PATROLDB" && !isEmpty(gSelectedPatrolDb) && floorNames &&
        <div className={style.floor__filter__div}>
          <label className={style.floor__filter__label} htmlFor="floor-filter">제외 필터:</label>
          <input className={style.floor__filter__input} id="floor-filter" value={floorFilterInput} onChange={(e) => setFloorFilterInput(e.target.value)} />
        </div>

      }
    </div>
  );
};

export default ViewerMenu;

