import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import defaultStyle from './C0300_ImageCanvas.module.css'
import { ObjectInterface } from '../interfaces';

interface ImageCanvasProps {
  styleUrl?: string;
  imgSrc: string;
  fitToWidth?: boolean,
}

interface Coord {
  x: number;
  y: number;
}
const ImageCanvas: React.FC<ImageCanvasProps> = ({
  styleUrl,
  imgSrc,
  fitToWidth,
}) => {

  const [style, setStyle] = useState<ObjectInterface>({});

  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);

  const [bgImg, setBgImg] = useState<HTMLImageElement>();
  const [canvasScale, setCanvasScale] = useState<number>(1);

  const [initDivWH, setInitDivWH] = useState<[width: Number, height: number]>([0, 0]);
  const [isDragging, setIsDragging] = useState(false);
  const [startPt, setStartPt] = useState<Coord>({ x: 0, y: 0 });
  const [wheelDeltaPt, setWheelDeltaPt] = useState<Coord>({ x: 0, y: 0 });


  /************************
   * Set styles custom / default
   */
  useEffect(() => {
    let isError = true;

    if (styleUrl) {
      import(styleUrl)
        .then((cssModule: ObjectInterface) => {
          setStyle(cssModule);
          isError = false;
        })
        .catch((error) => { console.error("error in importing custom cssfile: ", error) })
    }

    if (isError) {
      setStyle(defaultStyle);
    }
  }, [styleUrl])



  /*******************
   * Init context 
   */
  useLayoutEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');

      if (ctx) {
        setContext(ctx);
      }
    }

    if (canvasContainerRef.current) {
      setInitDivWH([canvasContainerRef.current.clientWidth, canvasContainerRef.current.clientHeight]);
    }
  }, [canvasRef]);

  /*******************
 * update context 
 */
  useEffect(() => {
    const canvas = canvasRef.current;
    const canvasContainer = canvasContainerRef.current;

    if (!canvasContainer || !canvas || !context || !bgImg) return;

    context.clearRect(0, 0, canvas.width, canvas.height);
    canvas.width = bgImg.width * canvasScale;
    canvas.height = bgImg.height * canvasScale;
    context.drawImage(bgImg, 0, 0, canvas.width, canvas.height);
    // canvasContainer.scrollBy(wheelDeltaPt.x, wheelDeltaPt.y)

    if (wheelDeltaPt.x !== 0 && wheelDeltaPt.y !== 0) {
      // canvasContainer.scrollBy(100, 100)

      console.log("wheel delta x,y: ", wheelDeltaPt.x, wheelDeltaPt.y)
    }

    setWheelDeltaPt({ x: 0, y: 0 })

  }, [bgImg, canvasRef, canvasScale, context, wheelDeltaPt.x, wheelDeltaPt.y]);


  /*******************
   * Loading image
   */
  useEffect(() => {
    if (context) {
      const canvas = canvasRef.current;
      const canvasContainer = canvasContainerRef.current;

      const image = new Image();

      image.onload = () => {
        if (canvas && canvasContainer) {


          let initScale = 1;

          if (fitToWidth) {
            initScale = canvasContainer.clientWidth / image.width;
          }
          canvas.width = image.width * initScale;
          canvas.height = image.height * initScale;

          // console.log("canvasContainer.clientWidth", canvasContainer.clientWidth);
          // console.log("initscale", initScale);
          // console.log("image.width", image.width);
          // console.log("canvas.width", canvas.width);
          // const rect = canvas.getBoundingClientRect();
          // console.log("boundary width", rect.width);
          // console.log("===========");



          context.drawImage(image, 0, 0, canvas.width, canvas.height);
          setBgImg(image);
          setCanvasScale(initScale);

        }
      }
      image.src = imgSrc;


    }
  }, [context, fitToWidth, imgSrc])




  /***********************
   * handler for canvas 
   */
  const handleCanvasOnMousedown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    if (e.button === 0) {
      canvas.style.cursor = "grabbing";
      setIsDragging(true);
      setStartPt({ x: e.clientX, y: e.clientY });
    }
  }

  const handleCanvasOnMouseUp = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    if (e.button === 0 && isDragging) {
      setIsDragging(false);
      canvas.style.cursor = "";
    }
  }

  const handleCanvasOnMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    const canvasContainer = canvasContainerRef.current;

    if (!canvasContainer || !canvas) return;

    if (e.button === 0 && isDragging) {
      const offsetX = startPt.x - e.clientX;
      const offsetY = startPt.y - e.clientY;

      canvasContainer.scrollBy(offsetX, offsetY);
      setStartPt({ x: e.clientX, y: e.clientY });
    }
  }

  const handleCanvasOnWheel = useCallback((e: WheelEvent) => {
    e.preventDefault();

    const canvasContainer = canvasContainerRef.current;
    const canvas = canvasRef.current;
    if (!canvasContainer || !canvas) return;

    const zoomSpeed = 0.05;
    let zoomFactor = 1 + zoomSpeed * (e.deltaY > 0 ? -1 : 1);

    console.log("DEBUG e.deltaY", e.deltaY, zoomFactor);

    const newCanvasScale = Math.min(Math.max(0.05, canvasScale * zoomFactor), 50)
    setCanvasScale(newCanvasScale); //zoom
    // setCanvasScale(prevScale => Math.min(Math.max(0.05, prevScale * zoomFactor), 50)); //zoom

    const rect = canvas.getBoundingClientRect();
    const mouseX = (e.clientX - rect.left) / canvasScale;
    const mouseY = (e.clientX - rect.left) / canvasScale;

    const deltaX = (mouseX * newCanvasScale) - mouseX;
    const deltaY = (mouseY * newCanvasScale) - mouseY;

    setWheelDeltaPt({ x: deltaX, y: deltaY });


  }, [canvasScale]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const theHandleCanvasOnWheel = (e: WheelEvent) => handleCanvasOnWheel(e);
    canvas.addEventListener('wheel', theHandleCanvasOnWheel, { passive: false });

    return () => {
      canvas.removeEventListener('wheel', theHandleCanvasOnWheel)
    }
  }, [handleCanvasOnWheel]);

  const parentDivWidth = initDivWH[0] !== 0 ? `${initDivWH[0]}px` : '100%';
  const parentDivHeight = initDivWH[1] !== 0 ? `${initDivWH[1]}px` : '100%';

  return (
    <div className={style.canvas__container} ref={canvasContainerRef} style={{ width: parentDivWidth, height: parentDivHeight }}>
      <canvas
        className={style.canvas__itself}
        ref={canvasRef}
        onMouseDown={handleCanvasOnMousedown}
        onMouseUp={handleCanvasOnMouseUp}
        onMouseMove={handleCanvasOnMouseMove}
      />
    </div>

  );
};



export default ImageCanvas;