import React, { useEffect, useState } from "react";
import defaultStyle from "./C0110_SpinnerChild.module.css";
import imgSpinner from "../assets/images/spinner.gif";
import { ObjectInterface } from "../interfaces";

interface SpinnerChildProps {
  styleObj?: ObjectInterface;
  message?: string;
  isOpen: boolean;
  onClose: () => void;
}
const SpinnerChild: React.FC<SpinnerChildProps> = ({
  styleObj,
  message,
  isOpen,
  onClose,
}) => {

  /****************************
  * Set styles custom / default
  ***********************************/
  const [style, setStyle] = useState<ObjectInterface>({});

  useEffect(() => {
    if (styleObj) {
      setStyle(styleObj);
    } else {
      setStyle(defaultStyle);
    }

  }, [styleObj])
  /***************************/

  /****** fade-in effect ************************/
  const [opacity, setOpacity] = useState(0);
  const transitionStyle = { transition: 'opacity 0.3s ease', opacity }
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpacity(1);
    }, 10);
  }, []);
  /*********************************************/

  /****** fade-out effect ************************/
  // const [nowOpen, setNowOpen] = useState<boolean>(isOpen);

  useEffect(() => {
    if (!isOpen) {
      setOpacity(0);
      setTimeout(() => {
        onClose();
      }, 300);
    }
  }, [isOpen, onClose]);

  const emergencyClose = () => {
    setOpacity(0);
    setTimeout(() => {
      onClose();
    }, 300);
  }
  /*********************************************/

  return (
    <div className={style.layout} style={transitionStyle}>
      <img className={style.img__spinner} src={imgSpinner} alt="loading.." />
      <p className={style.message}>{message ? message : "loading.."}</p>
      <div onMouseDown={emergencyClose} className={style.btn__emergency__close}>X</div>
    </div>
  );
};
export default SpinnerChild;
