import React, { useState } from "react";
import imgGraiLogo from "../assets/images/GRAI_Small_Transparent.png"
import imgGSLogo from "../assets/images/gslogo.png"

import styles from "./L0200_Header.module.css";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../context/GlobalContext";
import { MSGBOX_YESNO } from "../utils/commonConstants";
import ModalMsgBox from "../components/C0210_ModalMsgBox";
import { ObjectInterface } from "../interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";

interface HeaderProps {
  layoutStyle?: string;
  selectedMenu: string;
}

const HeaderLv1: React.FC<HeaderProps> = ({ layoutStyle, selectedMenu }) => {

  const { gUserAuthDetail } = useGlobalContext();

  console.log("selectedMenu:", selectedMenu);

  const MENUS: ObjectInterface = {
    'Site Selector': '/site',
    'Func Selector': '/func',
    // 'Robot Selector': '/robot',
  }

  if (gUserAuthDetail && gUserAuthDetail.is_master) {
    MENUS['Admin Pannel'] = "/admin";
  }

  const theLayoutStyle = `${layoutStyle ? layoutStyle : ""} ${styles.layout ? styles.layout : ""}`
  const { gUserId, updateGlobalContext, gSelectedSite } = useGlobalContext();
  const navigate = useNavigate();

  const handleGoLogin = () => {
    updateGlobalContext({
      gSelectedSite: null,
      gUserId: "",
      gUserAuthDetail: null,

    })
    navigate("/");
  }

  const [isMsgBoxOpen, setIsMsgBoxOpen] = useState<boolean>(false);
  const [targetSubUrl, setTargetSubUrl] = useState<string>("");

  const moveTo = (url: string) => {

    if (url === "/func" && gSelectedSite === null) return;

    navigate(url);
    // setIsMsgBoxOpen(true);
    // setTargetSubUrl(url);
    console.log(url);
  }

  const handleMsgBox = (selectedButton: string) => {
    setIsMsgBoxOpen(false);
    if (selectedButton === "Yes" && targetSubUrl !== "") {
      navigate(targetSubUrl);
    } else {
      setTargetSubUrl("");
    }
  }


  const [isFullScreen, setIsFullScreen] = useState(false);

  const switchFullScreen = () => {
    if (isFullScreen) {
      toExitFullScreen();
      setIsFullScreen(false);
      console.log("\nExit Full Screen");
    } else {
      toFullScreen();
      setIsFullScreen(true);
      console.log("\nFull Screen");
    }
  };

  const toFullScreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    }

    // if (document.documentElement.requestFullscreen) {
    //   document.documentElement.requestFullscreen();
    // } else if (document.documentElement.mozRequestFullScreen) { // Firefox
    //   document.documentElement.mozRequestFullScreen();
    // } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari, and Opera
    //   document.documentElement.webkitRequestFullscreen();
    // } else if (document.documentElement.msRequestFullscreen) { // Internet Explorer
    //   document.documentElement.msRequestFullscreen();
    // }
  };

  const toExitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }

    // if (document.exitFullscreen) {
    //   document.exitFullscreen();
    // } else if (document.mozCancelFullScreen) {
    //   document.mozCancelFullScreen();
    // } else if (document.webkitExitFullscreen) {
    //   document.webkitExitFullscreen();
    // } else if (document.msExitFullscreen) {
    //   document.msExitFullscreen();
    // }
  };


  return (
    <div className={theLayoutStyle}>
      <div className={styles.header__left} title="새로고침">
        <img onClick={handleGoLogin} className={styles.gslogo} src={imgGSLogo} alt="logo" />
        <img onClick={handleGoLogin} className={styles.grai_logo} src={imgGraiLogo} alt="logo" />


        <p className={styles.lblId}>{gUserId}</p>
      </div>
      <button
        data-mode={isFullScreen ? "expanded" : "normal"}
        onClick={switchFullScreen}
        className={styles.btn__expand}
      >
        {isFullScreen ? (
          <FontAwesomeIcon icon={faCompress} />
        ) : (
          <FontAwesomeIcon icon={faExpand} />
        )}
      </button>
      <div className={styles.header__right}>
        {Object.entries(MENUS).map(([menu, url]) => (
          <button
            key={menu}
            className={`${styles.button__header__menu} ${(menu === selectedMenu) ? styles.selected : ""}`}
            onClick={() => menu !== selectedMenu && moveTo(url)}>
            {menu}
          </button>
        ))}
      </div>
      {/* {isMsgBoxOpen && <ModalMsgBox
        isOpen={isMsgBoxOpen}
        buttons={MSGBOX_YESNO}
        title="Confirm"
        message="Are you sure to leave the current page?"
        colorName="warning"
        cbFunc={handleMsgBox} />} */}
    </div>
  );
};

export default HeaderLv1;
