import React, { useEffect, useState } from "react";
import defaultStyle from './C0210_ModalMsgBox.module.css'
import { ObjectInterface } from "../interfaces/commonTypes";
import { MSGBOX_COLOR_CODE, msgBoxColorNames } from "../utils/commonConstants";

interface ModalMsgBoxProps {
  isOpen: boolean,
  styleObj?: ObjectInterface;
  buttons: string[],
  title: string,
  message: string,
  colorName?: msgBoxColorNames,
  cbFunc: (selectedButton: string) => void;
}

const ModalMsgBox: React.FC<ModalMsgBoxProps> = (({
  isOpen,
  styleObj,
  buttons,
  title,
  message,
  colorName,
  cbFunc,
}) => {

  /******* * Set styles custom / default  *************************/
  const [style, setStyle] = useState<ObjectInterface>({});

  useEffect(() => {
    if (styleObj) {
      setStyle(styleObj);
    } else {
      setStyle(defaultStyle);
    }
  }, [styleObj])
  /***************************************************************/



  /****** fade-in effect ************************/

  const [transitionStyle, setTransitionStyle] = useState<ObjectInterface>({ transition: 'opacity 0.3s ease', opacity: "0", visibility: "hidden" })

  useEffect(() => {
    openThisModel();
  }, []);
  /*********************************************/


  /****** fade-out effect ************************/
  // const [nowOpen, setNowOpen] = useState<boolean>(isOpen);

  useEffect(() => {
    if (!isOpen) {
      closeThisModal();
    } else {
      openThisModel();
    }
  }, [isOpen, cbFunc]);

  const openThisModel = () => {
    setTimeout(() => {
      setTransitionStyle({ transition: 'opacity 0.3s ease', opacity: "1", visibility: "visible" })
    }, 10);
  }

  const closeThisModal = () => {
    setTransitionStyle({ transition: 'opacity 0.3s ease', opacity: "0" })
    setTimeout(() => {
      setTransitionStyle({ opacity: "0", visibility: "hidden" })
    }, 500);
  }
  /*********************************************/


  const theColorName: msgBoxColorNames = colorName ? colorName : 'normal'
  const bgColor = MSGBOX_COLOR_CODE[theColorName][0];
  const txtColor = MSGBOX_COLOR_CODE[theColorName][1];

  const handleBtnClick = (btnTitle: string) => {
    // closeThisModal();
    cbFunc(btnTitle);
    // closeThisModal();

  }

  return (
    <div className={style.layout} style={transitionStyle}>
      <div className={style.body__msgbox__container}>
        <div className={style.msgbox__container__title} style={{ "backgroundColor": bgColor, "color": txtColor }}>
          {title}
        </div>
        <div className={style.vdiv} style={{ "height": "350px" }}>
          <div className={style.msgbox__container__content}>
            <div className={style.msgbox__content__message}>
              <div>{message}</div>
            </div>
          </div>
          <div className={style.msgbox__container__buttons}>
            {buttons.map((theButton) =>
              <button key={theButton} className={style.msgbox__button} onClick={() => handleBtnClick(theButton)}>{theButton}</button>
            )}
          </div>

        </div>

      </div>
    </div>
  )
})


export default ModalMsgBox;