import { useState } from "react";
import { useGlobalContext } from "../context/GlobalContext";
import { ERROR_CODE_SESSION_OUT } from "../utils/commonConstants";


interface UseGetFetchReturn {
  data: string | null;
  loading: boolean;
  error: string | null;
  getFetchFunc: (subUrl: string) => Promise<void>;
}


function useGetFetchBlobUrl(): UseGetFetchReturn {
  const [data, setData] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { gApiKey, gBaseUrl, updateGlobalContext, gSessionToken } = useGlobalContext();


  const getFetchFunc = async (subUrl: string) => {
    setLoading(true);
    setError(null);

    const targetUrl = `${gBaseUrl}/${subUrl}`;
    // console.log("DEBUG tagetURL:", targetUrl);
    await fetch(targetUrl, {
      method: "GET",
      headers: {
        "X-API-Key": gApiKey,
        'Authorization': `Bearer ${gSessionToken}`
      }
    })
      .then((res) => {
        if (res.ok) return res.blob();

        if (res.status === ERROR_CODE_SESSION_OUT) {
          console.log("res.status === ERROR_CODE_SESSION_OUT")
          updateGlobalContext({ gIsLoggedIn: false })
        }

        throw new Error("Error in fetching");
      })
      .then((resBlob) => {
        setData(URL.createObjectURL(resBlob));
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return { data, loading, error, getFetchFunc }
}


export default useGetFetchBlobUrl;
