import React, { useEffect, useRef, useState } from "react";
import { ObjectInterface, ObjectInterfaceAny } from "../interfaces";
import { renderingConsoleLog } from "../utils/commonFunctions";
import imgMenuRobot from "../assets/images/menu_robot.png"
import style from "./L1200_FuncMenu.module.css"
import imgMenuViewer from "../assets/images/menu_viewer.png"
import imgMenuMapBuilder from "../assets/images/menu_map_builder.png"
import Footer from "./L0300_Footer";
import { useGlobalContext } from "../context/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import HeaderLv1 from "./L0210_HeaderLv1";

interface FuncMenuProps {
  styleObj?: ObjectInterface
}

const FuncMenu: React.FC<FuncMenuProps> = ({
  styleObj
}) => {

  /***** Performance Checker ***************/
  const renderCounter = useRef<number>(0);
  renderingConsoleLog({ componentName: "Main", cnt: renderCounter.current += 1 })

  const { gIsLoggedIn, gUserId, gSessionToken, gBaseUrl, gApiKey, gSelectedSite, gUserAuthDetail, updateGlobalContext } = useGlobalContext();
  const [ableOperation, setAbleOperation] = useState<boolean>(false);
  const [ableView, setAbleView] = useState<boolean>(false);
  const [ableEdit, setAbleEdit] = useState<boolean>(false);

  const navigate = useNavigate();
  const disableStyle = { "opacity": "0.2" }
  const siteName = gSelectedSite ? gSelectedSite.name : "";
  const siteDataId = gSelectedSite ? gSelectedSite.data_id : 0;

  useEffect(() => {
    updateGlobalContext({
      gSelectedSpaceDb: "",
      gSelectedMapName: "",
      gSelectedPatrolDb: "",
      gSelectedFloorName: "",
      gIdsForCheckedTaskResult: [],
      gFilteredTaskResultForNodes: {},
    })
  }, []);

  useEffect(() => {

    // console.log("R gSelectedSite", gSelectedSite)
    // console.log("R gUserAuthDetail", gUserAuthDetail)

    if (!gSelectedSite || !gUserAuthDetail) {
      return;
    }

    const site_data_id = gSelectedSite.data_id.toString();

    if (gUserAuthDetail.is_master) {
      setAbleOperation(true);
      setAbleView(true);
      setAbleEdit(true);
      return;
    }

    if (gUserAuthDetail.sites_ids_possible_operation_list.includes(site_data_id)) {
      setAbleOperation(true);
    }

    if (gUserAuthDetail.sites_ids_possible_edit_list.includes(site_data_id)) {
      setAbleEdit(true);
    }

    if (gUserAuthDetail.sites_ids_possible_view_list.includes(site_data_id)) {
      setAbleView(true);
    }


  }, [gUserAuthDetail, gSelectedSite])

  const moveTo = (where: string, newTab = false) => {
    switch (where) {
      case "Viewer":
        navigate("/viewer");
        break;
      case "MapBuilder":
        openLinkInNewTab("/mapbuilder");
        // navigate("/mapbuilder");
        break;
      case "Operation":
        // navigate("/operation");
        openLinkInNewTab("/operation");
        break;
    }

  }

  const openLinkInNewTab = (where: string) => {
    const newTab = window.open(`${window.location.origin}/spinner`, '_blank');

    const dataToOperator: ObjectInterfaceAny = {
      'gIsLoggedIn': gIsLoggedIn,
      'gUserId': gUserId,
      'gUserAuthDetail': gUserAuthDetail,
      'gBaseUrl': gBaseUrl,
      'gApiKey': gApiKey,
      'gSessionToken': gSessionToken,
      'gSelectedSite': gSelectedSite,
      'where': where,
    }

    let intervalCnt = 0;
    const intervalId = setInterval(() => {
      const bc = new BroadcastChannel('gs_robot_session');
      // bc.postMessage(dataToOperator);
      console.log("I sent2:", dataToOperator);
      bc.postMessage(dataToOperator);
      intervalCnt++;

      if (intervalCnt > 5) {
        clearInterval(intervalId)
      }
    }, 500);

  }

  const handleReturnToSiteSelector = () => {
    navigate("/site")
  }
  return <div className={style.layout}>
    <HeaderLv1 selectedMenu="Func Selector" />
    <div className={style.content}>
      <div className={style.title__container}>
        <p className={style.title}>Select the menu</p>
        <p className={style.subtitle}>(Site: {siteName} <span className={style.reload} onClick={handleReturnToSiteSelector} title="change the site"><FontAwesomeIcon icon={faArrowsRotate} /></span>) </p>
      </div>
      <div className={style.button__container}>
        <button className={style.btn__menu} disabled={!ableView} style={!ableView ? disableStyle : {}} onClick={() => { moveTo('Viewer') }}>
          <img className={style.btn__image} src={imgMenuViewer} alt="data_viewer"></img>
          <label>Data Review</label>
        </button>
        <button className={style.btn__menu} disabled={!ableOperation} style={!ableOperation ? disableStyle : {}} onClick={() => { moveTo('Operation') }}>
          <img className={style.btn__image} src={imgMenuRobot} alt="robot_operation"></img>
          <label>Robot Operation</label>
        </button>
        <button className={style.btn__menu} disabled={!ableEdit} style={!ableEdit ? disableStyle : {}} onClick={() => { moveTo('MapBuilder') }}>
          <img className={style.btn__image} src={imgMenuMapBuilder} alt="map_builder"></img>
          <label>Map Builder</label>
        </button>
      </div>
    </div>
    <Footer />

  </div>
}

export default FuncMenu;