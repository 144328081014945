import React from "react";
import styles from "./L0300_Footer.module.css";
import imgGSLogo from "../assets/images/gslogo.png";


interface FooterProps {
  layoutStyle?: string;
}
const Footer: React.FC<FooterProps> = ({ layoutStyle }) => {

  const theLayoutStyle = `${layoutStyle ? layoutStyle : ""} ${styles.layout ? styles.layout : ""}`

  return (
    <div className={theLayoutStyle}>
      <div></div>
      <div className={styles.footer__label}>
        © 2024 BIM Team, GS Engineering & Construction Corp.
      </div>
    </div>);
};

export default Footer;
