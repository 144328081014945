import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useGlobalContext } from "../context/GlobalContext";
import styles from "./C0310_KeyMap.module.css";
import pinIconSrc from "../assets/images/pinicon_s.png";
import robotPinSrc from "../assets/images/move05.png";
import { FetchMapFgBg, GeoInfo, TaskResultForNode, TaskResultInterface } from "../interfaces";
import { renderingConsoleLog } from "../utils/commonFunctions";


/**********************
 **** interface  ****
 **********************/
interface KeyMapProps {
  layoutStyle?: string;
  mapData: FetchMapFgBg;
  taskIdOnMouseOver: string;
  currentGeoInfo: GeoInfo | null | undefined;
  // updateBgImg: (bgImg: HTMLImageElement) => void;
}


const KeyMap: React.FC<KeyMapProps> = ({
  layoutStyle,
  mapData,
  taskIdOnMouseOver,
  currentGeoInfo }) => {

  // Performance Checker
  const renderCounter = useRef<number>(0);
  renderingConsoleLog({ componentName: "CenterMap", cnt: renderCounter.current += 1 })


  const {
    gSelectedMapName,
    gFilteredTaskResultForNodes,
    gIdsForCheckedTaskResult,
  } = useGlobalContext();

  /***********************
   **** variables: ref ***
   **********************/
  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);



  /***********************
   **** variables: state *
   **********************/
  const [bgImg, setBgImg] = useState<HTMLImageElement>();
  const [canvasScale, setCanvasScale] = useState<number>(1);
  const [mapResolution, setMapResolution] = useState<number>(0);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);

  const [pinIcon, setPinIcon] = useState<HTMLImageElement>();
  const [robotIcon, setRobotIcon] = useState<HTMLImageElement>();


  /***********************
   **** handle prop map data *
   **********************/
  useEffect(() => {
    if (mapData &&
      mapData.map_file_fg
    ) {

      setMapResolution(mapData.map_resolution);

      const bgImageSrc = mapData.map_file_fg;
      const canvas = canvasRef.current;
      const canvasContainer = canvasContainerRef.current;

      if (context) {
        const image = new Image();
        image.onload = () => {
          if (canvas && canvasContainer) {

            const newScale = canvasContainer.clientWidth / image.width;
            canvas.width = image.width * newScale;
            canvas.height = image.height * newScale;
            context.drawImage(image, 0, 0, canvas.width, canvas.height);
            // context.drawImage(image, 0, 0, image.width, image.height);

            setBgImg(image);
            setCanvasScale(newScale);
          }
        };
        image.src = bgImageSrc;
      }
    }
  }, [mapData, context]);
  /*********************************/



  /***********************
   **** init context *
   **********************/
  useLayoutEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');

      if (ctx) {
        setContext(ctx);
      }
    }
  }, [canvasRef]);


  useEffect(() => {
    if (!mapResolution) return;

    // init Pin Image
    const thePinIcon = new Image();
    thePinIcon.onload = () => {

      const pinWidth = 1 // meter
      const pinLength = 1.33 // meter
      const width = Math.round(pinWidth / mapResolution);
      const height = Math.round(pinLength / mapResolution);

      // console.log(`original pinIcon: w:${thePinIcon.width}, h${thePinIcon.height}`)
      thePinIcon.width = width;
      thePinIcon.height = height;
      // console.log(`changed pinIcon: w:${thePinIcon.width}, h${thePinIcon.height}`)
      setPinIcon(thePinIcon);
    }
    thePinIcon.src = pinIconSrc;

    // init Robot Image
    const theRobotIcon = new Image();
    theRobotIcon.onload = () => {

      const robotImageScale = 1;
      const imgWidth = 0.8 * robotImageScale // meter
      const imgLength = 1.2 * robotImageScale// meter
      const width = Math.round(imgWidth / mapResolution);
      const height = Math.round(imgLength / mapResolution);

      theRobotIcon.width = width;
      theRobotIcon.height = height;
      setRobotIcon(theRobotIcon);
    }
    theRobotIcon.src = robotPinSrc;

  }, [mapResolution]);


  /**********************
   **** function: draw circle on filtered nodes ***
   **********************/

  /**********************
   * **** Draw a Rubot
   ****************/

  const drawCurrentPoint = useCallback(
    (geoInfo: GeoInfo, circleRadius: number, circleColor: string, dirArcColor: string) => {
      if (!context || mapResolution === 0) return false

      const translateX = geoInfo.x;
      const translateY = geoInfo.y;
      const angleInRadians = geoInfo.rot;


      context.save();
      context.translate(translateX * canvasScale, translateY * canvasScale);
      context.rotate(Math.PI / 2 - angleInRadians);


      context.beginPath();
      context.arc(0, 0, circleRadius, 0, Math.PI * 2, false);
      context.fillStyle = circleColor;
      context.fill();
      // context.stroke()
      context.closePath();

      context.beginPath();
      context.arc(0, 0, circleRadius, -Math.PI / 20 - Math.PI / 2, Math.PI / 20 - Math.PI / 2);
      context.fillStyle = dirArcColor //'rgba(255,0,0,1)'
      context.fill();
      context.lineWidth = circleRadius;
      context.strokeStyle = dirArcColor //
      context.stroke();
      context.closePath();
    }, [canvasScale, context, mapResolution]);

  const drawRobot = useCallback((
    translateX: number,
    translateY: number,
    angleInRadians: number,
    robotImage: HTMLImageElement,
    robotScale: number,
    circlColor: string,
    dirArcColor: string,
    willDrawBgCircle?: boolean) => {

    if (!context || mapResolution === 0) return false

    if (willDrawBgCircle === undefined) {
      willDrawBgCircle = false;
    }

    const width = Math.round(robotImage.width * canvasScale * robotScale)
    const height = Math.round(robotImage.height * canvasScale * robotScale)

    const topLeftX = -1 * width / 2;
    const topLeftY = -1 * height / 2;

    context.save();
    context.translate(translateX * canvasScale, translateY * canvasScale);
    context.rotate(Math.PI / 2 - angleInRadians);


    if (willDrawBgCircle) {
      const circleRadius = height;

      context.beginPath();
      context.arc(0, 0, circleRadius, 0, Math.PI * 2, false);
      context.fillStyle = circlColor;
      context.fill();
      // context.stroke()
      context.closePath();

      context.beginPath();
      context.arc(0, 0, circleRadius, -Math.PI / 20 - Math.PI / 2, Math.PI / 20 - Math.PI / 2);
      context.fillStyle = dirArcColor //'rgba(255,0,0,1)'
      context.fill();
      context.lineWidth = circleRadius * 2 / 3;
      context.strokeStyle = dirArcColor //
      context.stroke();
      context.closePath();
    }

    context.drawImage(robotImage, topLeftX, topLeftY, width, height);
    context.restore();
  }, [canvasScale, context, mapResolution])


  const drawNodes = useCallback(() => {
    if (!context || !pinIcon || !robotIcon || mapResolution === 0) return;
    if (!gFilteredTaskResultForNodes) return;

    const resultListTodrawLaterClicked: Array<TaskResultForNode> = [];
    const resultListTodrawLaterOnHover: Array<TaskResultForNode> = [];

    Object.entries(gFilteredTaskResultForNodes).forEach(([id, theTaskResultForNode]) => {
      // console.log("[DEBUG] theTaskResultForNode", theTaskResultForNode);

      const theFirstRecord = theTaskResultForNode.record[Object.keys(theTaskResultForNode.record)[0]];

      const x = theFirstRecord.geo_info.x // * canvasScale;
      const y = theFirstRecord.geo_info.y  // * canvasScale;
      const rot = theFirstRecord.geo_info.rot;

      const robotScale = 1;
      const circlColor = 'rgba(153,153,255,0.5)';
      const dirArcColor = 'rgba(153,153,255,1)';

      if (gIdsForCheckedTaskResult.includes(id)) {
        resultListTodrawLaterClicked.push(theTaskResultForNode);
        return;

      }

      if (id === taskIdOnMouseOver) {
        resultListTodrawLaterOnHover.push(theTaskResultForNode);
        return;
      }
      drawRobot(x, y, rot, robotIcon, robotScale, circlColor, dirArcColor, true);
    })

    resultListTodrawLaterClicked.forEach(theTaskResultForNode => {
      const theFirstRecord = theTaskResultForNode.record[Object.keys(theTaskResultForNode.record)[0]];

      const x = theFirstRecord.geo_info.x // * canvasScale;
      const y = theFirstRecord.geo_info.y  // * canvasScale;
      const rot = theFirstRecord.geo_info.rot;

      const robotScale = 1.5;
      // const circlColor = 'rgba(0,200,153,0.5)';
      // const dirArcColor = 'rgba(255,0,0,1)';

      const circlColor = 'rgba(255,255,255,0)';
      const dirArcColor = 'rgba(255,255,255,0)';


      drawRobot(x, y, rot, robotIcon, robotScale, circlColor, dirArcColor, true);
    })

    resultListTodrawLaterOnHover.forEach(theTaskResultForNode => {
      const theFirstRecord = theTaskResultForNode.record[Object.keys(theTaskResultForNode.record)[0]];

      const x = theFirstRecord.geo_info.x // * canvasScale;
      const y = theFirstRecord.geo_info.y  // * canvasScale;
      const rot = theFirstRecord.geo_info.rot;

      const robotScale = 1.2;
      const circlColor = 'rgba(0,200,153,0.5)';
      const dirArcColor = 'rgba(0,200,153,1)';

      drawRobot(x, y, rot, robotIcon, robotScale, circlColor, dirArcColor, true);
    })


  }, [context, drawRobot, taskIdOnMouseOver, gFilteredTaskResultForNodes, gIdsForCheckedTaskResult, mapResolution, pinIcon, robotIcon])


  /*************
   * BACKUP FOR LEARNING
   * ******************* */
  // const x = theFirstRecord.geo_info.x * canvasScale - (pinIcon.width / 2) * canvasScale;
  // const y = theFirstRecord.geo_info.y * canvasScale - (pinIcon.height / 2) * canvasScale;
  // context.drawImage(pinIcon, x, y, pinIcon.width * canvasScale, pinIcon.height * canvasScale);

  // const x = theFirstRecord.geo_info.x * canvasScale - (pinIcon.width / 2);
  // const y = theFirstRecord.geo_info.y * canvasScale - (pinIcon.height / 2);
  // context.drawImage(pinIcon, x, y, pinIcon.width, pinIcon.height);

  //     context.beginPath();
  //     context.arc(x, y, pointSize, 0, 2 * Math.PI);
  //     context.fillStyle = "rgba(224, 100, 105, 0.4)";
  //     context.fill();
  //     context.closePath();



  /**********************
   **** function: init background with the map image ***
   **********************/
  const initBackground = useCallback(() => {
    if (!context || mapResolution === 0 || !bgImg) return;

    context.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);

    // canvasRef.current!.width = bgImg.width * canvasScale;
    // canvasRef.current!.height = bgImg.height * canvasScale;

    const canvasContainer = canvasContainerRef.current;
    canvasRef.current!.width = canvasContainer!.clientWidth;
    canvasRef.current!.height = canvasRef.current!.width * bgImg.height / bgImg.width;

    const newScale = canvasRef.current!.width / bgImg.width
    setCanvasScale(newScale);
    context.drawImage(bgImg, 0, 0, canvasRef.current!.width, canvasRef.current!.height);
  }, [bgImg, canvasScale, context, mapResolution]);



  /**********************
   **** canvas: main update ***
   **********************/

  useEffect(() => {
    initBackground();
    drawNodes();

    if (currentGeoInfo) {
      const radius = 2 * canvasScale / mapResolution;
      const circlColor = 'rgba(255,0,0,0.5)';
      const dirArcColor = 'rgba(255,0,0,1)';

      drawCurrentPoint(currentGeoInfo, radius, circlColor, dirArcColor);
    }

  }, [currentGeoInfo, drawCurrentPoint, drawNodes, initBackground]);



  /**********************
   **** canvas event handler  ***
   **********************/

  // Mouse Down: 
  const onMouseDownHandler = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;

    if (!canvas) return;

    if (e.button === 0) {
      canvas.style.cursor = "grabbing";
      setIsDragging(true);
      setStartX(e.clientX);
      setStartY(e.clientY);
    }

  };

  // Mouse Up
  const onMouseUpHandler = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    if (e.button === 0 && isDragging) {
      setIsDragging(false);
      canvas.style.cursor = "";
    };
  }

  // Mouse Move
  const onMouseMoveHandler = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    const canvasContainer = canvasContainerRef.current;
    if (!canvasContainer || !canvas) return;

    if (e.button === 0 && isDragging) {
      const offsetX = startX - e.clientX;
      const offsetY = startY - e.clientY;
      canvasContainer.scrollBy(offsetX, offsetY);
      setStartX(e.clientX);
      setStartY(e.clientY);
    }
  }

  // Wheel: Zooming
  const onMouseWheelHandler = (e: React.WheelEvent<HTMLCanvasElement>) => {
    const zoomSpeed = 0.1;
    let zoomFactor = 1 + zoomSpeed * (e.deltaY > 0 ? -1 : 1);
    const newCanvasScale = Math.min(
      Math.max(0.05, canvasScale * zoomFactor),
      50
    );

    setCanvasScale(newCanvasScale);
  };


  const theLayoutStyle = `${layoutStyle ? layoutStyle : ""} ${styles.layout ? styles.layout : ""}`

  return (
    <div ref={canvasContainerRef} className={styles.layout}>
      <canvas
        className={styles.canvas__itself}
        ref={canvasRef}
        onMouseDown={onMouseDownHandler}
        onMouseUp={onMouseUpHandler}
        onMouseMove={onMouseMoveHandler}
      // onWheel={onMouseWheelHandler}
      />
    </div>
  );
};

export default KeyMap;
