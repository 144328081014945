import React, { useEffect, useState } from "react";
import defaultStyle from './C0435_DateRangeSelector.module.css';
import { ObjectInterface } from "../interfaces";
import DatePicker from "./C0430_DatePicker";

interface DateRangeSelectorProps {
  styleUrl?: string;
  title: string;
  fromInitYYYYMMDD: string;
  toInitYYYYMMDD: string;
  cbFunc: (fromDate?: Date, toDate?: Date) => void;
}
const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  styleUrl,
  title,
  fromInitYYYYMMDD,
  toInitYYYYMMDD,
  cbFunc
}) => {

  /****************************
 *  style={{ ...transitionStyle }}
* fade-in effect *********/
  const [opacity, setOpacity] = useState(0);
  const transitionStyle = { transition: 'opacity 0.5s ease', opacity }
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpacity(1);
    }, 10);
  }, []);
  /**************************/

  /************************
 * Set styles custom / default
 */
  const [style, setStyle] = useState<ObjectInterface>({});

  useEffect(() => {
    let isError = true;

    if (styleUrl) {
      import(styleUrl)
        .then((cssModule: ObjectInterface) => {
          setStyle(cssModule);
          isError = false;
        })
        .catch((error) => { console.error("error in importing custom cssfile: ", error) })
    }

    if (isError) {
      setStyle(defaultStyle);
    }
  }, [styleUrl])
  /***************************/

  /**************************
   * Init and Handler for Date Selector
   */
  const [fromUtcDate, setFromUtcDate] = useState<Date>();
  const [toUtcDate, setToUtcDate] = useState<Date>();

  useEffect(() => {
    console.log("fromInitYYYYMMDD: ", fromInitYYYYMMDD);
    console.log("toInitYYYYMMDD: ", toInitYYYYMMDD);

    const initFromDate: Date = new Date(`${fromInitYYYYMMDD}T00:00:00`)
    setFromUtcDate(initFromDate)

    const initToDate = new Date(`${toInitYYYYMMDD}T23:59:59`)
    setToUtcDate(initToDate)

    console.log("fromInit Date: ", initFromDate);
    console.log("toInit Date: ", initToDate);

  }, [fromInitYYYYMMDD, toInitYYYYMMDD]);

  const handlerFromDate = (date: Date) => {
    if (date) {
      const newFromDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);

      console.log("handlerFromDate");
      console.log("  given date:", date)
      console.log("  new date:", newFromDate);
      console.log("  existing to date:", toUtcDate);

      setFromUtcDate(newFromDate);
      // cbFunc(newFromDate, newToDate);
      cbFunc(newFromDate, toUtcDate);
    }
  }

  const handlerToDate = (date: Date) => {
    if (date) {
      const newToDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59.99);

      setToUtcDate(newToDate);
      // cbFunc(newFromDate, newToDate);
      cbFunc(fromUtcDate, newToDate);

    }
  }

  // useEffect(() => {
  //   console.log("fromInitYYYYMMDD", fromInitYYYYMMDD);
  //   console.log("toInitYYYYMMDD", toInitYYYYMMDD);
  // }, [toInitYYYYMMDD, fromInitYYYYMMDD])

  return (
    <div className={style.layout} style={{ ...transitionStyle }}>
      <div className={style.select__header}>{title}</div>
      <div className={style.range__container}>
        <DatePicker title="From:" initDate={fromInitYYYYMMDD} localDatePicker={handlerFromDate} />
        <DatePicker title="To:" initDate={toInitYYYYMMDD} localDatePicker={handlerToDate} />
      </div>
    </div>
  )
}


export default DateRangeSelector;