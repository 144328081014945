import { useState } from "react";
import { useGlobalContext } from "../context/GlobalContext";
import { ERROR_CODE_SESSION_OUT } from "../utils/commonConstants";

interface UseDeleteFetchReturn<T> {
  data: T | null;
  loading: boolean;
  error: string | null;
  deleteFetchFunc: (subUrl: string) => Promise<void>;
}

function useDeleteFetch<T>(): UseDeleteFetchReturn<T> {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { gApiKey, gBaseUrl, updateGlobalContext, gSessionToken } = useGlobalContext();

  const deleteFetchFunc = async (subUrl: string) => {
    setLoading(true);
    setError(null);

    const targetUrl = `${gBaseUrl}/${subUrl}`;

    await fetch(targetUrl, {
      method: "DELETE",
      headers: {
        "X-API-Key": gApiKey,
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${gSessionToken}`
      }
    })
      .then((res) => {
        if (res.ok) return res.json();

        if (res.status === ERROR_CODE_SESSION_OUT) {
          console.log("res.status === ERROR_CODE_SESSION_OUT")
          updateGlobalContext({ gIsLoggedIn: false })
        }

        throw new Error("Error in fetching");
      })
      .then((resJson) => {
        setData(resJson);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return { data, loading, error, deleteFetchFunc }
}

export default useDeleteFetch;
