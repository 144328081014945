import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";

import { ObjectInterface, FilteredTaskResultForNodes, TaskResultInterface, TaskResultForNode, UserAuthDetail, DBMasterSite } from "../interfaces";

interface GlobalContextType {
  gApiKey: string;
  gBaseUrl: string;
  gSessionToken: string;
  gIsLoggedIn: boolean;

  gUserId: string;
  gUserAuthDetail: UserAuthDetail | null;

  gMapNames: string[];
  gFloorNameToMapName: ObjectInterface | null;
  gSelectedSite: DBMasterSite | null;
  gSelectedSpaceDb: string;
  gSelectedPatrolDb: string;
  gSelectedFloorName: string;
  gSelectedMapName: string;
  gMapLoading: boolean;


  gActionItems: string[];

  gSelectedPatrolUTCTimeStampsRange: { from: string, to: string } | null;
  gSelectedGroupByItems: string[];
  gSelectedActionItems: string[];

  gTaskResults: TaskResultInterface[];
  gFilteredTaskResultForNodes: FilteredTaskResultForNodes | null;
  gIdsForCheckedTaskResult: string[];

  updateGlobalContext: (value: UpdateGlobalContextType) => void;
}

interface UpdateGlobalContextType {
  gApiKey?: string;
  gBaseUrl?: string;
  gSessionToken?: string;
  gIsLoggedIn?: boolean;

  gUserId?: string;
  gUserAuthDetail?: UserAuthDetail | null;

  gMapNames?: string[];
  gFloorNameToMapName?: ObjectInterface | null;
  gSelectedSite?: DBMasterSite | null;
  gSelectedSpaceDb?: string;
  gSelectedPatrolDb?: string;
  gSelectedFloorName?: string;
  gSelectedMapName?: string;
  gMapLoading?: boolean;

  gActionItems?: string[];

  gSelectedPatrolUTCTimeStampsRange?: { from: string, to: string } | null;
  gSelectedGroupByItems?: string[];
  gSelectedActionItems?: string[];

  gTaskResults?: TaskResultInterface[];
  gFilteredTaskResultForNodes?: FilteredTaskResultForNodes | null;
  gIdsForCheckedTaskResult?: string[];
}

const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const [gApiKey, gSetApiKey] = useState<string>("");
  const [gBaseUrl, gSetBaseUrl] = useState<string>("");
  const [gSessionToken, gSetSessionToken] = useState<string>("");
  const [gIsLoggedIn, gSetIsLoggedIn] = useState<boolean>(false);

  const [gUserId, gSetUserId] = useState<string>("");
  const [gUserAuthDetail, gSetUserAuthDetail] = useState<UserAuthDetail | null>(null);

  const [gMapNames, gSetMapNames] = useState<string[]>([]);
  const [gFloorNameToMapName, gSetLayerNameToMapName] = useState<ObjectInterface | null>(null);

  const [gSelectedSite, gSetSelectedSite] = useState<DBMasterSite | null>(null);
  const [gSelectedSpaceDb, gSetSelectedSpaceDb] = useState<string>("");
  const [gSelectedPatrolDb, gSetSelectedPatrolDb] = useState<string>("");
  const [gSelectedFloorName, gSetSelectedLayerName] = useState<string>("");
  const [gSelectedMapName, gSetSelectedMapName] = useState<string>("");
  const [gMapLoading, gSetDataLoaded] = useState<boolean>(false);


  const [gActionItems, gSetActionItems] = useState<string[]>([]);

  const [gSelectedPatrolUTCTimeStampsRange, gSetSelectedPatrolUTCTimeStampsRange] = useState<{ from: string, to: string } | null>(null);
  const [gSelectedGroupByItems, gSetSelectedGroupByItems] = useState<string[]>([]);
  const [gSelectedActionItems, gSetSelectedActionItems] = useState<string[]>([]);

  const [gTaskResults, gSetTaskResults] = useState<TaskResultInterface[]>([]);
  const [gFilteredTaskResultForNodes, gSetFilteredTaskResultForNodes] = useState<FilteredTaskResultForNodes | null>(null);
  const [gIdsForCheckedTaskResult, gSetIdsForCheckedTaskResult] = useState<string[]>([]);

  const updateGlobalContext = useCallback(
    (targetObj: UpdateGlobalContextType): void => {
      // console.log("\n------------------------");
      // console.log("** updateGlobalContext!!:", targetObj);
      // console.log("------------------------\n");


      if (targetObj.gApiKey !== undefined) gSetApiKey(targetObj.gApiKey);
      if (targetObj.gBaseUrl !== undefined) gSetBaseUrl(targetObj.gBaseUrl);
      if (targetObj.gSessionToken !== undefined) gSetSessionToken(targetObj.gSessionToken);
      if (targetObj.gIsLoggedIn !== undefined) gSetIsLoggedIn(targetObj.gIsLoggedIn);

      if (targetObj.gUserId !== undefined) gSetUserId(targetObj.gUserId);
      if (targetObj.gUserAuthDetail !== undefined) gSetUserAuthDetail(targetObj.gUserAuthDetail);

      if (targetObj.gMapNames !== undefined) gSetMapNames(targetObj.gMapNames);
      if (targetObj.gFloorNameToMapName !== undefined) gSetLayerNameToMapName(targetObj.gFloorNameToMapName);

      if (targetObj.gSelectedSite !== undefined) gSetSelectedSite(targetObj.gSelectedSite);
      if (targetObj.gSelectedSpaceDb !== undefined) gSetSelectedSpaceDb(targetObj.gSelectedSpaceDb);
      if (targetObj.gSelectedPatrolDb !== undefined) gSetSelectedPatrolDb(targetObj.gSelectedPatrolDb);
      if (targetObj.gSelectedFloorName !== undefined) gSetSelectedLayerName(targetObj.gSelectedFloorName);
      if (targetObj.gSelectedMapName !== undefined) gSetSelectedMapName(targetObj.gSelectedMapName);
      if (targetObj.gMapLoading !== undefined) gSetDataLoaded(targetObj.gMapLoading);


      if (targetObj.gActionItems !== undefined) gSetActionItems(targetObj.gActionItems);

      if (targetObj.gSelectedPatrolUTCTimeStampsRange !== undefined) gSetSelectedPatrolUTCTimeStampsRange(targetObj.gSelectedPatrolUTCTimeStampsRange);
      if (targetObj.gSelectedGroupByItems !== undefined) gSetSelectedGroupByItems(targetObj.gSelectedGroupByItems);
      if (targetObj.gSelectedActionItems !== undefined) gSetSelectedActionItems(targetObj.gSelectedActionItems);

      if (targetObj.gTaskResults !== undefined) gSetTaskResults(targetObj.gTaskResults);
      if (targetObj.gFilteredTaskResultForNodes !== undefined) gSetFilteredTaskResultForNodes(targetObj.gFilteredTaskResultForNodes);
      if (targetObj.gIdsForCheckedTaskResult !== undefined) gSetIdsForCheckedTaskResult(targetObj.gIdsForCheckedTaskResult);
      // if (targetObj.gIdsForCheckedTaskResult !== undefined) gSetIdsForCheckedTaskResult(() => targetObj.gIdsForCheckedTaskResult || []);

      // if (targetObj.g ) gSet(targetObj.g );
    },
    []
  );

  return (
    <GlobalContext.Provider
      value={{
        gApiKey,
        gBaseUrl,
        gSessionToken,
        gIsLoggedIn,
        gUserId,
        gUserAuthDetail,
        gMapNames,
        gFloorNameToMapName,
        gSelectedSite,
        gSelectedSpaceDb,
        gSelectedPatrolDb,
        gSelectedFloorName,
        gSelectedMapName,
        gMapLoading,

        gActionItems,

        gSelectedPatrolUTCTimeStampsRange,
        gSelectedGroupByItems,
        gSelectedActionItems,

        gTaskResults,
        gFilteredTaskResultForNodes,
        gIdsForCheckedTaskResult,

        updateGlobalContext,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = (): GlobalContextType => {
  const context = useContext(GlobalContext);

  if (context === undefined) {
    throw new Error("useGlobalContext must be used within a GlobalProvider");
  }

  return context;
};
