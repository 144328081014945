import React, { useEffect, useRef, useState } from "react";
import { useGlobalContext } from "../context/GlobalContext";
import ImgGraiLogo from "../assets/images/GRAI_Large_Transparent_with_gslogo.png";
import styles from "./L0100_LoginPage.module.css";
import { UPDATE_DATE } from "../utils/commonConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [userId, setUserId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [key, setKey] = useState<string>("bim");

  const [isUrlChanging, setIsUrlChanging] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const refInputUrl = useRef<HTMLInputElement>(null);
  const refInputKey = useRef<HTMLInputElement>(null);
  const refInputUserId = useRef<HTMLInputElement>(null);
  const refInputPassword = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const [loginResultMsg, setLoginResultMsg] = useState<string>(
    "ID와 PW를 입력하세요. (필요시, 접속대상 로봇 API URL을 수정하세요.)"
  );

  const { updateGlobalContext, gBaseUrl } = useGlobalContext();
  const [url, setUrl] = useState<string>(gBaseUrl ? gBaseUrl : "https://gsrobot01.gsenc.com:9500");

  useEffect(() => {
    updateGlobalContext({
      gIsLoggedIn: false,
      gSelectedSite: undefined
    })

    const savedBaseUrl = localStorage.getItem("gsenc.baseurl");
    if (savedBaseUrl) {
      setUrl(savedBaseUrl);
    }

    // console.log("gIsLoggedIn updated");
  }, []);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    if (isLoading) return;

    setIsLoading(true);

    if (url.trim() === "") {
      setLoginResultMsg("URL 칸이 비어 있습니다.");
      refInputUrl.current!.focus();
      setIsLoading(false);
      return;
    }

    if (userId.trim() === "") {
      setLoginResultMsg("ID 칸이 비어 있습니다.");
      refInputUserId.current!.focus();
      setIsLoading(false);
      return;
    }

    try {
      const targetUrl = `${url}/login`;

      const response = await fetch(targetUrl, {
        method: "POST",
        headers: {
          "X-API-Key": key,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId: userId, password: password })
      });

      const resJson = await response.json();

      if (response.ok) {
        if (resJson.success && resJson.sessionToken && resJson.userAuthDetail) {

          updateGlobalContext({
            gIsLoggedIn: true,
            gApiKey: key,
            gBaseUrl: url,
            gUserAuthDetail: resJson.userAuthDetail,
            gUserId: userId,
            gSessionToken: resJson.sessionToken
          });

          localStorage.setItem("gsenc.baseurl", url);
          // localStorage.setItem("gsenc.apikey", key);

          setLoginResultMsg("Login succeeded");
          navigate("/site");
        } else {
          updateGlobalContext({ gIsLoggedIn: false });
          if (resJson.reason === "id") {
            setLoginResultMsg("Please check your id");
          } else if (resJson.reason === "password") {
            setLoginResultMsg("Please check your password");
          } else {
            setLoginResultMsg("Please check your id/password");
          }

          refInputUserId.current!.focus();
        }
      } else {
        updateGlobalContext({ gIsLoggedIn: false });
        setLoginResultMsg("Please check API url");
        refInputUrl.current!.focus();
      }
    } catch (error) {
      updateGlobalContext({ gIsLoggedIn: false });
      setLoginResultMsg("Please check API url");
      refInputUrl.current!.focus();

    }

    setIsLoading(false);
  };

  const [isFullScreen, setIsFullScreen] = useState(false);

  const switchFullScreen = () => {
    if (isFullScreen) {
      toExitFullScreen();
      setIsFullScreen(false);
      console.log("\nExit Full Screen");
    } else {
      toFullScreen();
      setIsFullScreen(true);
      console.log("\nFull Screen");
    }
  };

  const toFullScreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    }

    // if (document.documentElement.requestFullscreen) {
    //   document.documentElement.requestFullscreen();
    // } else if (document.documentElement.mozRequestFullScreen) { // Firefox
    //   document.documentElement.mozRequestFullScreen();
    // } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari, and Opera
    //   document.documentElement.webkitRequestFullscreen();
    // } else if (document.documentElement.msRequestFullscreen) { // Internet Explorer
    //   document.documentElement.msRequestFullscreen();
    // }
  };

  const toExitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }

    // if (document.exitFullscreen) {
    //   document.exitFullscreen();
    // } else if (document.mozCancelFullScreen) {
    //   document.mozCancelFullScreen();
    // } else if (document.webkitExitFullscreen) {
    //   document.webkitExitFullscreen();
    // } else if (document.msExitFullscreen) {
    //   document.msExitFullscreen();
    // }
  };

  return (
    <div className={styles.main}>
      <img className={styles.img__logo} src={ImgGraiLogo} alt="G.RAI" />

      <p className="txt__small">last update: {UPDATE_DATE}</p>
      <p className={`${styles.login__message} blink-slow`}>{loginResultMsg}</p>
      <button
        data-mode={isFullScreen ? "expanded" : "normal"}
        onClick={switchFullScreen}
        className={styles.btn__expand}
      >
        {isFullScreen ? (
          <FontAwesomeIcon icon={faCompress} />
        ) : (
          <FontAwesomeIcon icon={faExpand} />
        )}
      </button>
      <form className={styles.login__form} onSubmit={handleLogin}>
        <div className={styles.login__form__row}>
          <label
            className={styles.login__form__label}
            htmlFor="id"
            onDoubleClick={() => { setIsUrlChanging(!isUrlChanging) }}
            onTouchEnd={() => { setIsUrlChanging(!isUrlChanging) }}
            title="Doubl Click to chagne api url">
            URL:
          </label>
          <input
            className={styles.login__form__input}
            id="url"
            type="text"
            placeholder="Robot URL"
            value={url}
            ref={refInputUrl}
            disabled={!isUrlChanging}
            onChange={(e) => setUrl(e.target.value)}
            style={{ color: isUrlChanging ? "white" : "gray" }}
          />
        </div>
        <div className={styles.login__form__row}>
          <label className={styles.login__form__label} htmlFor="userId">
            ID:
          </label>
          <input
            className={styles.login__form__input}
            id="userId"
            value={userId}
            ref={refInputUserId}
            onChange={(e) => setUserId(e.target.value)}
          />
        </div>
        <div className={styles.login__form__row}>
          <label className={styles.login__form__label} htmlFor="password">
            PW:
          </label>
          <input
            className={styles.login__form__input}
            id="password"
            type="password"
            value={password}
            ref={refInputPassword}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button className={styles.login__form__button} type="submit">
          {isLoading ? "Logging in ..." : "Login"}
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
