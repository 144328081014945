import React, { useEffect, useRef, useState } from 'react';
import defaultStyle from './C0430_DatePicker.module.css'
import { ObjectInterface } from '../interfaces';

interface DatePickerProps {
  styleUrl?: string;
  title: string;
  initDate?: string;
  localDatePicker?: (localDate: Date) => void;
  utcDatePicker?: (utcDate: Date) => void;
}
const DatePicker: React.FC<DatePickerProps> = ({
  styleUrl,
  title,
  initDate,
  localDatePicker,
  utcDatePicker,
}) => {

  // Performance Checker
  // const renderCounter = useRef<number>(0);
  // renderingConsoleLog({componentName: "Date Picker", cnt: renderCounter.current+= 1})


  /************************
   * Set styles custom / default
   */
  const [style, setStyle] = useState<ObjectInterface>({});

  useEffect(() => {
    let isError = true;

    if (styleUrl) {
      import(styleUrl)
        .then((cssModule: ObjectInterface) => {
          setStyle(cssModule);
          isError = false;
        })
        .catch((error) => { console.error("error in importing custom cssfile: ", error) })
    }

    if (isError) {
      setStyle(defaultStyle);
    }
  }, [styleUrl])
  /***************************/


  const [selectedDate, setSelectedDate] = useState<string>(initDate ? initDate : ""); // Initialize with an empty string or a specific date

  useEffect(() => {
    // console.log("DatePicker - initDate", initDate);
    // console.log("DatePicker - selectedDate", selectedDate);

    if (initDate) {
      setSelectedDate(initDate);
    }

  }, [initDate, selectedDate])

  // Event handler for when the date changes
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(e.target.value);

    const newLocalDate = e.target.value ? new Date(e.target.value) : null;
    const newUtcDate = e.target.value ? new Date(e.target.value + 'T00:00:00') : null;


    if (localDatePicker && newLocalDate) {
      // console.log("LOCAL DATE PICKER:", newLocalDate.toString());
      localDatePicker(newLocalDate);
    }
    if (utcDatePicker && newUtcDate) utcDatePicker(newUtcDate);

  };

  return (
    <div className={style.layout}>
      <label className={style.label__date} htmlFor="date-picker">{title}</label>
      <input
        className={style.input__date}
        type="date"
        id="date-picker"
        value={selectedDate}
        onChange={handleDateChange}
        style={{ marginLeft: '10px' }}
      />
    </div>
  );
};

export default DatePicker;