import React, { useEffect, useState } from "react";
import { ObjectInterface } from "../interfaces";
import defaultStyle from "./C0600_SelectCard.module.css";
import imgDummyImg from "../assets/images/site_dummy_icon.png";
import useGetFetchBlobUrl from "../hooks/useGetFetchBlobUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import useDeleteFetch from "../hooks/useDeleteFetch";
import { useGlobalContext } from "../context/GlobalContext";

interface SelectCardProps {
  styleObj?: ObjectInterface;
  imgSubUrl: string;
  title: string;
  txtTitleValuePairJson: ObjectInterface;
  txtOnMouseOver?: string;
  dataId: number;
  isDeleteFuncOn: boolean;
  cbFunc: (selectedId?: number) => void;
}

const SelectCard: React.FC<SelectCardProps> = ({
  styleObj,
  dataId,
  imgSubUrl,
  title,
  txtTitleValuePairJson,
  txtOnMouseOver,
  isDeleteFuncOn,
  cbFunc,
}) => {
  const [style, setStyle] = useState<ObjectInterface>({});
  const [finalImage, setFinalImage] = useState<string>(imgDummyImg);

  useEffect(() => {
    if (styleObj) {
      setStyle(styleObj);
    } else {
      setStyle(defaultStyle);
    }
  }, [styleObj]);

  const { gUserAuthDetail, gSelectedSite, updateGlobalContext } = useGlobalContext();
  const { data, loading, error, getFetchFunc } = useGetFetchBlobUrl();
  const { data: deleteSiteResponse, loading: deleteSiteLoading, error: deleteSiteError, deleteFetchFunc } = useDeleteFetch<any>();

  function handleClick() {
    cbFunc(dataId);
  }

  function handleDelete() {
    const userSelect = window.confirm("Delete?");

    if (userSelect) {
      if (gSelectedSite && gSelectedSite.data_id === dataId) {
        updateGlobalContext({ gSelectedSite: null });
      }

      deleteFetchFunc(`database/site/delete/${dataId}`).then(() => {
        cbFunc(); // Call the callback function to refresh the parent component
      });
    }
  }

  useEffect(() => {
    getFetchFunc(imgSubUrl).finally(() => { });
  }, [imgSubUrl]);

  useEffect(() => {
    if (!loading) {
      if (error || data === null || data === undefined) {
        setFinalImage(imgDummyImg);
      } else {
        setFinalImage(data);
      }
    }
  }, [data]);

  return (
    <div className={style.overalllayout}>
      <button className={style.layout} title={txtOnMouseOver} onClick={handleClick}>
        <div className={style.image__container}>
          <img className={style.image} src={finalImage} alt="card_image" />
        </div>
        <div className={style.title__container}>{title}</div>
        <div className={style.txt__container}>
          {Object.entries(txtTitleValuePairJson).map(([key, value]) => (
            <div key={key} className={style.txt__row}>
              <li key={key}>{key}</li>
              <p>{value}</p>
            </div>
          ))}
        </div>
      </button>
      {gUserAuthDetail && gUserAuthDetail.is_master && isDeleteFuncOn && (
        <button className={style.del__button} onClick={handleDelete}>
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
      )}
    </div>
  );
};

export default SelectCard;
