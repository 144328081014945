import React, { ReactNode, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import style from "./L4210_ViewerContentMain.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faX } from '@fortawesome/free-solid-svg-icons';
import { useGlobalContext } from '../context/GlobalContext';
import ToggleButtonGroupDetailViewer from '../components/C0441_ToggleButtonGroupDetailViewer';
import { convertArrayToObject, convertUTCsDateTimeToLocal, degreeToRadian, getFileName, getUrlFromOldFileHyperLink, isDateWithinRange, isEmpty, radianToDegree } from '../utils/commonFunctions';
import { GeoInfo, ObjectInterface, PanoramaMotion, TaskResultForNode, TaskResultInterface } from '../interfaces';
import ImageCanvas from '../components/C0300_ImageCanvas';
import KeyMap from '../components/C0310_KeyMap';
import FloatingWindow from '../components/C0200_FloatingWindow';
import ListSelector from '../components/C0420_ListSelector';
import thisListStyle from './L4210_myC0420_ListSelector.module.css';
import myFloatingStyle from "./L4210_myC0200_FloatingWindow.module.css"
import SensorViewer from '../components/C0320_SensorViewer';
import { ACTION_ITEMS, INIT_PANORAMA_MOTION } from '../utils/commonConstants';
import PanoramaViewer from '../components/C0330_PanoramaViewer';
import ThermalViewer from '../components/C0340_ThermalViewer';
import CombinedViewer from '../components/C0360_CombinedViewer';


interface ContentViewerInterface {
  updateCurrentGeoInfo: (geoInfo: GeoInfo) => void;
}


const ContentViewer: React.FC<ContentViewerInterface> = ({
  updateCurrentGeoInfo
}) => {


  const {
    gSelectedPatrolUTCTimeStampsRange,
    gIdsForCheckedTaskResult,
    gFilteredTaskResultForNodes,
    gBaseUrl,
    gSelectedSite
  } = useGlobalContext();

  const [thisTaskResultForNode, setThisTaskResultForNode] = useState<TaskResultForNode>();
  const [thisSelectedActionType, setThisSelectedActionType] = useState<string>("All");
  const [slidePage, setSlidePage] = useState<number>(1); // 1~
  const [slideLength, setSlideLength] = useState<number>(0);

  const [thisSelectedIdForTaskResultForNode, setThisSelectedIdForTaskResultForNode] = useState<string>("");
  const [thisSelectedTimeStamp, setThisSelectedTimeStamp] = useState<string>('');
  const [validTimeStampMap, setValidTimeStampMap] = useState<ObjectInterface>({});
  const [sortedValidUTCList, setSortedValidUTCList] = useState<string[]>([]);

  const [overlayMsg, setOverlayMsg] = useState<string>("");



  /**************** fade-in effect **********************/
  const [opacity, setOpacity] = useState(0);
  const transitionStyle = { transition: 'opacity 0.5s ease', opacity }
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpacity(1);
    }, 10);
  }, []);
  /******  style={{ ...transitionStyle }}  **************/


  useEffect(() => {
    console.log("XXX gIdsForCheckedTaskResult:", gIdsForCheckedTaskResult);

    if (gIdsForCheckedTaskResult.length === 0) return;

    setThisSelectedIdForTaskResultForNode(gIdsForCheckedTaskResult[0]);
    // console.warn("DEBUG", gIdsForCheckedTaskResult[0]);
  }, [gIdsForCheckedTaskResult]);


  /**********************
 * init data
 ***********************/

  useEffect(() => {
    if (gIdsForCheckedTaskResult.length === 0) return;
    if (!gFilteredTaskResultForNodes) return;

    const allTimeStampList = gFilteredTaskResultForNodes[gIdsForCheckedTaskResult[0]].patrol_utc_timestamp_list;
    const validMapObj: ObjectInterface = {};
    const validList: string[] = [];

    allTimeStampList.forEach(theTime => {
      // console.log("DEBUG thistime:", theTime);
      const newTheTime = theTime.slice(0, "2023-04-15T12:00:00.000".length) + "Z"
      // console.log("DEBUG thistime Z:", newTheTime);

      if (gSelectedPatrolUTCTimeStampsRange && isDateWithinRange(newTheTime, gSelectedPatrolUTCTimeStampsRange.from, gSelectedPatrolUTCTimeStampsRange.to)) {
        // validMapObj[theTime] = convertUTCsDateTimeToLocal(theTime, true);
        validMapObj[theTime] = convertUTCsDateTimeToLocal(newTheTime, false);
        validList.push(theTime);
      }
    })

    // console.log(validMapObj);
    validList.sort();

    /********** S: INITIALIZING FOR EVERY LOCATION UPDATE!!!  **************/
    gFilteredTaskResultForNodes && setThisTaskResultForNode(gFilteredTaskResultForNodes[gIdsForCheckedTaskResult[0]]);
    setPrevPanoramaMotion(INIT_PANORAMA_MOTION);
    setValidTimeStampMap(validMapObj);
    setSortedValidUTCList(validList);

    console.log("validList[validList.length - 1]", validList[validList.length - 1]);
    console.log("validList", validList);
    // console.log("fron, to", gSelectedPatrolUTCTimeStampsRange.from, gSelectedPatrolUTCTimeStampsRange.to);

    if (validList.length === 0) {
      setThisSelectedTimeStamp('');
      setSlideLength(0);
      setSlidePage(0);
    } else {
      setThisSelectedTimeStamp(validList[validList.length - 1]);
      setSlideLength(validList.length);
      setSlidePage(validList.length);
    }
    /********** E: INITIALIZING FOR EVERY LOCATION UPDATE!!!  **************/

  }, [gFilteredTaskResultForNodes, gIdsForCheckedTaskResult, gSelectedPatrolUTCTimeStampsRange])






  /**********************
  * event handler
  ***********************/

  useEffect(() => {
    console.log("sortedValidUTCList[slidePage - 1]", sortedValidUTCList[slidePage - 1]);
    setThisSelectedTimeStamp(sortedValidUTCList[slidePage - 1]);
  }, [slidePage, sortedValidUTCList]);

  const handleArrowLeft = () => {
    // console.log("DEBUG slidePage: ", slidePage);
    if (slidePage > 1) {
      setSlidePage(slidePage - 1);
      setOverlayMsg("");
    } else {
      setOverlayMsg("This is the oldest record.");
      setTimeout(() => {
        setOverlayMsg("");
      }, 3000);
    }
  }

  const handleArrowRight = () => {
    if (slidePage < slideLength) {
      setSlidePage(slidePage + 1);
      setOverlayMsg("");
    } else {
      setOverlayMsg("This is the latest record.");
      setTimeout(() => {
        setOverlayMsg("");
      }, 3000);
    }

  }

  // param for initial value of the panorama view
  const [prevPanoramaMotion, setPrevPanoramaMotion] = useState<PanoramaMotion>(INIT_PANORAMA_MOTION);

  // function to update view dir and location of the picture 
  const updateGeoInfoWithPanoramMotion = useCallback((geoInfo: GeoInfo, panoramaMotion: PanoramaMotion | null) => {
    const updatedGeoInfo = { ...geoInfo };

    if (panoramaMotion) {
      updatedGeoInfo.rot -= degreeToRadian(panoramaMotion.yawDeg);
      setPrevPanoramaMotion(panoramaMotion);
    }

    updateCurrentGeoInfo(updatedGeoInfo);
  }, [updateCurrentGeoInfo]);


  // callback function to get view points o f the panorama view;
  const updatePanoramaMotion = useCallback((updatedMotion: PanoramaMotion) => {
    if (thisTaskResultForNode) {
      const geoInfo = thisTaskResultForNode.record[thisSelectedTimeStamp].geo_info;
      updateGeoInfoWithPanoramMotion(geoInfo, updatedMotion);
    }
  }, [thisSelectedTimeStamp, thisTaskResultForNode, updateGeoInfoWithPanoramMotion]);




  // function to initial panorama motion when the action type is changed
  useEffect(() => {
    if (thisSelectedActionType === "CAM360_IMAGE") {
      updatePanoramaMotion(INIT_PANORAMA_MOTION);
    }
  }, [thisSelectedActionType, updatePanoramaMotion]);



  // event function to handle toggle button for action types 
  const handleMenuToggleButtons = (key: string, value: string) => {
    setThisSelectedActionType(key);
    // console.log("[DEBUG] selected action type:", key, value);
  }


  // event handler when the date selector is changed 
  const handleDateSelector = (selectedValue: string) => {
    // console.log("DATE CHANGE SELECTOR", selectedValue);
    setThisSelectedTimeStamp(selectedValue);
    setSlidePage(sortedValidUTCList.indexOf(selectedValue) + 1);
  }


  useEffect(() => {
    if (!thisTaskResultForNode || !thisSelectedTimeStamp) return;

    const geoInfo = thisTaskResultForNode.record[thisSelectedTimeStamp].geo_info;
    updateGeoInfoWithPanoramMotion(geoInfo, prevPanoramaMotion);

  }, [thisTaskResultForNode, thisSelectedTimeStamp, updateCurrentGeoInfo, updateGeoInfoWithPanoramMotion, prevPanoramaMotion])



  // initialize view after 'thisTaskResultForNode" is updated which means the user select another location.
  const [disabledKeyList, setDisabledKeyList] = useState<string[]>([]);
  useEffect(() => {
    if (!thisTaskResultForNode) return;

    const theDisabledKeyList = Object.keys(ACTION_ITEMS).filter((theKey) => !thisTaskResultForNode.action_type_list.includes(theKey));
    setDisabledKeyList(theDisabledKeyList);

  }, [thisTaskResultForNode]);



  /************************************************
   * Function to create main viewer ***************
   ***********************************************/
  const mainViewer = useCallback((
    thePrevPanoramaMotion: PanoramaMotion,
    theThisSelectedActionType: string,
    theThisSelectedTimeStamp: string,
    theThisTaskResultForNode: TaskResultForNode | null | undefined,
    theUpdatePanoramaMotion: (data: PanoramaMotion) => void
  ): JSX.Element => {
    if (!gSelectedSite || !theThisTaskResultForNode || !theThisSelectedTimeStamp || !theThisSelectedActionType) return <div className={style.blank__view}>select location.</div>;

    if (theThisSelectedActionType === "All") {

      let theResult, forWhichData;


      forWhichData = "CAM_COMBINED_IMAGE"
      theResult = theThisTaskResultForNode.record[theThisSelectedTimeStamp].results[forWhichData];
      let frontView = <div className={style.blank__view}>No front cam image</div>

      if (theResult) {
        const thumbnail = (theResult.data.file as string).slice(1)
        const imgUrl = getUrlFromOldFileHyperLink(
          gBaseUrl,
          "cam_images",
          gSelectedSite.subfolder_title,
          thumbnail)

        if (imgUrl) {
          frontView = <ImageCanvas imgSrc={imgUrl} fitToWidth={true} />
        }
      }


      forWhichData = "SENSOR"
      theResult = theThisTaskResultForNode.record[theThisSelectedTimeStamp].results[forWhichData];
      let sensorView = <div className={style.blank__view}>No sensor data</div>

      if (theResult) {
        const sensorData = theResult.data;
        if (sensorData) {
          sensorView = <SensorViewer sensorData={sensorData} />
        }
      }


      forWhichData = "THERMAL_MSG"
      theResult = theThisTaskResultForNode.record[theThisSelectedTimeStamp].results[forWhichData];
      let thermalView = <div className={style.blank__view}>No thermal data</div>

      if (theResult) {
        let thermalFile = theResult.data.file;

        if (thermalFile) {
          thermalFile = thermalFile as string;
          thermalFile = getFileName(thermalFile)
          thermalView = <ThermalViewer
            fileName={thermalFile}
            siteFolderName={gSelectedSite.subfolder_title} />
        }
      }


      forWhichData = "CAM360_IMAGE"
      theResult = theThisTaskResultForNode.record[theThisSelectedTimeStamp].results[forWhichData];
      let panoramaView = <div className={style.blank__view}>No panorama data</div>

      if (theResult) {
        let panoramaFile = theResult.data.file
        let previewFile = theResult.data.thumbnail;

        if (panoramaFile) {
          panoramaFile = panoramaFile as string;

          const panoramaUrl = getUrlFromOldFileHyperLink(
            gBaseUrl,
            "cam_images_360",
            gSelectedSite.subfolder_title,
            panoramaFile.slice(1))

          let previewUrl: string | undefined = undefined;

          if (previewFile) {
            previewFile = previewFile as string;

            previewUrl = getUrlFromOldFileHyperLink(
              gBaseUrl,
              "cam_images_360",
              gSelectedSite.subfolder_title,
              previewFile.slice(1))
          }

          panoramaView = <PanoramaViewer
            key={panoramaUrl}
            panoramaUrl={panoramaUrl}
            previewUrl={previewUrl}
            updatePanoramaMotion={theUpdatePanoramaMotion}
            initPanoramaMotion={thePrevPanoramaMotion}
          />
        }
      }



      return <CombinedViewer
        frontView={frontView}
        sensorView={sensorView}
        thermalView={thermalView}
        panoramaView={panoramaView}
      />
    }

    const theResult = theThisTaskResultForNode.record[theThisSelectedTimeStamp].results[theThisSelectedActionType];

    if (theResult) {
      switch (theThisSelectedActionType) {
        case 'CAM_COMBINED_IMAGE':
          const thumbnail = (theResult.data.file as string).slice(1)
          const imgUrl = getUrlFromOldFileHyperLink(
            gBaseUrl,
            "cam_images",
            gSelectedSite.subfolder_title,
            thumbnail)

          return <ImageCanvas imgSrc={imgUrl} fitToWidth={true} />

        case "SENSOR":
          const sensorData = theResult.data;
          if (sensorData) {
            return <SensorViewer sensorData={sensorData} />
          } else {
            return <div className={style.blank__view}>data is not available for {ACTION_ITEMS[theThisSelectedActionType]} on {convertUTCsDateTimeToLocal(theThisSelectedTimeStamp)}</div>
          }

        case "CAM360_IMAGE":
          let panoramaFile = theResult.data.file
          let previewFile = theResult.data.thumbnail;

          if (panoramaFile) {
            panoramaFile = panoramaFile as string;
            // const panoramaUrl = `${gBaseUrl}${panoramaFile.slice(1)}`

            const panoramaUrl = getUrlFromOldFileHyperLink(
              gBaseUrl,
              "cam_images_360",
              gSelectedSite.subfolder_title,
              panoramaFile.slice(1))

            let previewUrl: string | undefined = undefined;

            if (previewFile) {
              previewFile = previewFile as string;

              previewUrl = getUrlFromOldFileHyperLink(
                gBaseUrl,
                "cam_images_360",
                gSelectedSite.subfolder_title,
                previewFile.slice(1))
            }

            // console.log("CONTENT VIEWER panoramaUrl", panoramaUrl);
            return (
              <PanoramaViewer
                key={panoramaUrl}
                panoramaUrl={panoramaUrl}
                previewUrl={previewUrl}
                updatePanoramaMotion={theUpdatePanoramaMotion}
                initPanoramaMotion={thePrevPanoramaMotion}
              />)
          } else {
            return <div className={style.blank__view}>data is not available for {ACTION_ITEMS[theThisSelectedActionType]} on {convertUTCsDateTimeToLocal(theThisSelectedTimeStamp)}</div>
          }

        case "THERMAL_MSG":
          let thermalFile = theResult.data.file;

          if (thermalFile) {
            thermalFile = thermalFile as string;
            thermalFile = getFileName(thermalFile)
            // const newThermalFile = getUrlFromOldFileHyperLink(
            //   gBaseUrl,
            //   "thermal_data",
            //   gSelectedSite.subfolder_title,
            //   thermalFile)

            return (
              <ThermalViewer
                fileName={thermalFile}
                siteFolderName={gSelectedSite.subfolder_title}
              />
            )

          } else {
            return <div className={style.blank__view}>data is not available for {ACTION_ITEMS[theThisSelectedActionType]} on {convertUTCsDateTimeToLocal(theThisSelectedTimeStamp)}</div>
          }



        default:
          return <div className={style.blank__view}>no data</div>
      }
    } else {
      return <div className={style.blank__view}>data is not available for {ACTION_ITEMS[theThisSelectedActionType]} on {convertUTCsDateTimeToLocal(theThisSelectedTimeStamp)}</div>
    }

  }, [gBaseUrl]);



  /************************************************
   * Function to create description subwindow *****
   ***********************************************/
  const detailDescription: JSX.Element = useMemo(() => {
    if (!thisTaskResultForNode || !thisSelectedTimeStamp) return <></>;

    console.log("DEBUG thisSelectedTimeStamp", thisSelectedTimeStamp);

    const thisActionType = thisSelectedActionType === "All" ? "CAM_COMBINED_IMAGE" : thisSelectedActionType;
    const theResult = thisTaskResultForNode.record[thisSelectedTimeStamp].results[thisActionType];

    return (theResult &&
      <div className={style.description__layout}>
        <div className={`${style.description__row} ${style.large__txt}`}>
          <span>건물명 :</span>
          <span>{thisTaskResultForNode.filter.space_db_title}</span>
        </div>
        <div className={`${style.description__row} ${style.large__txt}`}>
          <span>촬영층 :</span>
          <span>{thisTaskResultForNode.filter.layer_title}</span>
        </div>
        <div className={`${style.description__row} ${style.large__txt}`}>
          <span>순찰명:</span>
          <span>{thisTaskResultForNode.filter.patrol_title}</span>
        </div>
        <br />
        <hr />
        <br />
        <div className={style.description__row}>
          <span>촬영시점</span>
          <span>{theResult.action_utc_time_stamp_start ? convertUTCsDateTimeToLocal(theResult.action_utc_time_stamp_start) : ""}</span>
        </div>
        <div className={style.description__row}>
          <span>도면정보 :</span>
          <span>{thisTaskResultForNode.map_title}</span>
        </div>
        <div className={style.description__row}>
          <span>지점번호:</span>
          <span>{thisTaskResultForNode.filter.node_number}</span>
        </div>



        {/* <div className={style.description__row}>
          <span>Id</span>
          <span>{theResult.id}</span>
        </div>
        <div className={style.description__row}>
          <span>Action Type</span>
          <span>{thisSelectedActionType}</span>
        </div> */}

        {/* <div className={style.description__row}>
          <pre>
            {gTaskResults && JSON.stringify(gTaskResults, null, 2)}
          </pre>
        </div> */}
      </div>)
  }, [thisTaskResultForNode, thisSelectedTimeStamp, thisSelectedActionType]);

  const [menus, setMenus] = useState<ObjectInterface>(ACTION_ITEMS);

  useEffect(() => {
    setMenus({ 'All': 'All', ...ACTION_ITEMS });

    console.log("menus:", menus);
    console.log("menu2:", { 'All': 'All', ...ACTION_ITEMS });

  }, [])

  return (
    <div className={style.layout} style={{ ...transitionStyle }}>
      <div className={style.center__container}>
        <div className={style.center__top}>
          {validTimeStampMap &&
            <ListSelector
              title="timestamp"
              items={validTimeStampMap}
              itemLoading={false}
              styleObj={thisListStyle}
              defaultKey={thisSelectedTimeStamp}
              cbFunc={handleDateSelector} />}
        </div>


        <div className={style.center__middle}>
          <button onClick={handleArrowLeft} className={`${style.arrow__container} ${style.left} ${slidePage <= 1 ? style.disabled : ""}`}><FontAwesomeIcon icon={faAngleLeft} /></button>
          <button onClick={handleArrowRight} className={`${style.arrow__container}  ${style.right}  ${slidePage >= slideLength ? style.disabled : ""}`}><FontAwesomeIcon icon={faAngleRight} /></button>
          {overlayMsg && overlayMsg !== "" && <div className={style.overlaymsg}><pre>{overlayMsg}</pre></div>}
          {mainViewer(prevPanoramaMotion, thisSelectedActionType, thisSelectedTimeStamp, thisTaskResultForNode, updatePanoramaMotion)}

          <FloatingWindow
            title={`촬영정보`}
            isPinned={false}
            contentObj={detailDescription}
            styleObj={myFloatingStyle}
          />
        </div>

        <div className={style.center__footer}>
          <ToggleButtonGroupDetailViewer
            layoutStyle={style.toggle__bottom}
            items={{ 'All': 'All', ...ACTION_ITEMS }}
            disabledKeys={disabledKeyList}
            initKey={'All'}
            cbFunc={handleMenuToggleButtons}
            itemLoading={false}
          />
          <div className={style.pages}></div>
        </div>

      </div>
    </div>
  );
};


export default ContentViewer;