import React, { useState } from "react";
import imgGraiLogo from "../assets/images/GRAI_Small_Transparent.png"
import imgGSLogo from "../assets/images/gslogo.png"

import styles from "./L0200_Header.module.css";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../context/GlobalContext";
import { MSGBOX_YESNO } from "../utils/commonConstants";
import ModalMsgBox from "../components/C0210_ModalMsgBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faRotate } from "@fortawesome/free-solid-svg-icons";

interface HeaderProps {
  layoutStyle?: string;
  selectedMenu: string;
}

const Header: React.FC<HeaderProps> = ({ layoutStyle, selectedMenu }) => {

  const MENUS = {
  }

  const theLayoutStyle = `${layoutStyle ? layoutStyle : ""} ${styles.layout ? styles.layout : ""}`
  const { gUserId, updateGlobalContext, gSelectedSite, gUserAuthDetail } = useGlobalContext();
  const navigate = useNavigate();

  const handleGoLogin = () => {
    updateGlobalContext({
      gSelectedSite: null,
      gUserId: "",
      gUserAuthDetail: null,

    })
    navigate("/");
  }

  function handleReloadSite() {
    navigate("/site");
  }

  function handleReloadFunc() {
    navigate("/func");
  }

  const [isMsgBoxOpen, setIsMsgBoxOpen] = useState<boolean>(false);
  const [targetSubUrl, setTargetSubUrl] = useState<string>("");

  const moveTo = (url: string) => {
    setTargetSubUrl(url);
    setIsMsgBoxOpen(true);
  }

  const handleGoAdminPannel = () => {
    navigate("/admin");
  }

  const handleMsgBox = (selectedButton: string) => {
    if (selectedButton === "Yes" && targetSubUrl !== "") {
      navigate(targetSubUrl);
    } else {
      setTargetSubUrl("");
    }
  }

  return (
    <div className={theLayoutStyle}>
      {isMsgBoxOpen && <ModalMsgBox
        isOpen={isMsgBoxOpen}
        buttons={MSGBOX_YESNO}
        title="Confirm"
        message="Are you sure to leave the current page?"
        colorName="warning"
        cbFunc={handleMsgBox} />}

      <div className={styles.header__left} title="새로고침">
        <img onClick={handleGoLogin} className={styles.gslogo} src={imgGSLogo} alt="logo" />
        <img onClick={handleGoLogin} className={styles.grai_logo} src={imgGraiLogo} alt="logo" />

        {/* <p className={styles.header__left__txt}>Robot Control Platform</p> */}
        <p className={styles.lblId}>{gUserId}</p>
      </div>
      <div className={styles.header__right}>
        {/* {Object.entries(MENUS).map(([menu, url]) => (
          <button
            className={`${styles.button__header__menu} ${(menu === selectedMenu) ? styles.selected : ""}`}
            onClick={() => moveTo(url)}>
            {menu}
          </button>
        ))} */}
        <div className={styles.header__site__name}>
          <button title="Click to change the site" className={styles.header__site__label} onClick={handleReloadSite}>Site: {gSelectedSite?.name}</button>
          <button title="Click to change the function" className={styles.header__site__reload} onClick={handleReloadFunc}><FontAwesomeIcon icon={faRotate} /></button>
          {gUserAuthDetail?.is_master && <button title="Admin pannel" className={styles.header__admin__pannel} onClick={handleGoAdminPannel}><FontAwesomeIcon icon={faGear} /></button>}
        </div>
      </div>

    </div>
  );
};

export default Header;
