import React, { memo, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import defaultStyle from "./C0420_ListSelector.module.css";
import { ObjectInterface } from "../interfaces";
import useGetFetch from "../hooks/useGetFetch";
import { convertArrayToObject } from "../utils/commonFunctions";
import { isElementAccessExpression } from "typescript";
import useGetFetch2 from "../hooks/useGetFetch2";

interface ListSelectorProps {
  title: string;
  items?: ObjectInterface;
  itemsFetchUrl?: string;
  cbFunc: (value: string) => void;
  styleUrl?: string;
  styleObj?: ObjectInterface;
  itemLoading?: boolean;
  headerTitle?: string;
  defaultKey?: string;
}

const ListSelector: React.FC<ListSelectorProps> = ({
  title,
  items,
  itemsFetchUrl,
  styleUrl,
  styleObj,
  cbFunc,
  itemLoading,
  headerTitle,
  defaultKey
}) => {

  /**************** fade-in effect **********************/
  const [opacity, setOpacity] = useState(0);
  const transitionStyle = { transition: 'opacity 0.5s ease', opacity }
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpacity(1);
    }, 10);
  }, []);
  /******  style={{ ...transitionStyle }}  **************/

  const [finalItems, setFinalItems] = useState<ObjectInterface | undefined>(items);
  // const { data, loading, error } = useGetFetch<{ list: string[] }>(itemsFetchUrl ? itemsFetchUrl : "", []); // Assuming useGetFetch is generic and data is an array of strings
  const { data, loading, error, getFetchFunc } = useGetFetch2<{ list: string[] }>(); // Assuming useGetFetch is generic and data is an array of strings

  useEffect(() => {
    if (itemsFetchUrl) {
      getFetchFunc(itemsFetchUrl).finally(() => { });
    }

  }, [itemsFetchUrl]);

  const [selectedKey, setSelectedKey] = useState(defaultKey ? defaultKey : "");

  useEffect(() => {
    if (!finalItems && !loading && data) {

      const theItems = convertArrayToObject<string>(data.list, false);
      setFinalItems(theItems);
    } else {
      if (items) {
        setFinalItems(items);
      }
    }
  }, [loading, data, finalItems, items]);

  useEffect(() => {
    if (defaultKey) {
      setSelectedKey(defaultKey);
    }

  }, [defaultKey]);


  const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    console.log("[DEBUG-selected item from ListSelector]", e.target.value);
    setSelectedKey(e.target.value)
    cbFunc(e.target.value);
  };

  /******* * Set styles custom / default  *************************/
  const [style, setStyle] = useState<ObjectInterface>({});

  useEffect(() => {
    if (styleObj) {
      setStyle(styleObj);
    } else {
      setStyle(defaultStyle);
    }

  }, [styleObj])
  /***************************************************************/

  return (
    <div className={style.layout} style={{ ...transitionStyle }}>
      <div className={style.overalcontainer}>
        {headerTitle && <div className={style.select__header}>{headerTitle}</div>}
        {itemLoading && <FontAwesomeIcon icon={faSpinner} spin />}
        {!itemLoading && finalItems && (
          <select className={style.select__db} onChange={handleOptionChange} value={selectedKey}>
            <option value="">---select {title} --</option>
            {Object.keys(finalItems).map((key) => (
              <option key={key} value={key}>
                {finalItems[key]}
              </option>
            ))}
          </select>
        )}
      </div>
    </div >
  );
};

export default memo(ListSelector);
