import React, { useCallback, useEffect, useState } from "react";
import { DBMasterSite, ObjectInterface } from "../interfaces";
import style from './L1100_SiteSelector.module.css';
import SelectCard from "../components/C0600_SelectCard";
import Footer from "./L0300_Footer";
import SelectCardAdd from "../components/C0601_SelectCardAdd";
import imgDummyImg from "../assets/images/site.png";
import SiteAdder from "./L1110_SiteAdder";
import useGetFetch2 from "../hooks/useGetFetch2";
import Spinner from "../components/C0100_Spinner";
import HeaderLv1 from "./L0210_HeaderLv1";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../context/GlobalContext";

interface SiteGetFetchInterface {
  success: boolean;
  data: DBMasterSite[];
}

interface SiteSelectorProps {
  styleObj?: ObjectInterface;
  cbFunc?: (selectedSite: DBMasterSite) => void;
}

const SiteSelector: React.FC<SiteSelectorProps> = ({ styleObj }) => {
  const { updateGlobalContext, gUserAuthDetail } = useGlobalContext();
  const [sitesData, setSitesData] = useState<DBMasterSite[]>([]);
  const [siteImgSrc, setSiteImgSrc] = useState<ObjectInterface>({});
  const [isSpinnerOpen, setIsSpinnerOpen] = useState<boolean>(true);
  const [spinnerMessage, setSpinnerMessage] = useState<string>("loading");
  const [isSiteAdderOpen, setIsSiteAdderOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const { data: site_data, loading: site_loading, error: site_error, getFetchFunc } = useGetFetch2<SiteGetFetchInterface>();

  useEffect(() => {
    if (!isSiteAdderOpen) {
      getFetchFunc('database/site/getlist').finally(() => { });
    }
  }, [isSiteAdderOpen]);

  useEffect(() => {
    updateGlobalContext({
      gSelectedSpaceDb: "",
      gSelectedMapName: "",
      gSelectedPatrolDb: "",
      gSelectedFloorName: "",
      gIdsForCheckedTaskResult: [],
      gFilteredTaskResultForNodes: {},
    })
  }, []);

  useEffect(() => {
    if (!site_loading && site_data) {
      if (site_data.success) {
        setSitesData(site_data.data);

        site_data.data.forEach((theSiteData) => {
          const imageLocation = theSiteData.img_location;
          const imageUrl = `file/site_image/${theSiteData.subfolder_title}/site_photo.jpg`;

          setSiteImgSrc((prevImgSrc) => ({ ...prevImgSrc, [theSiteData.name]: imageUrl }));
        });

        setIsSpinnerOpen(false);
      } else {
        setSpinnerMessage("Downloading site data is failed. please check the connectivity");
      }
    }
  }, [site_data, site_loading]);

  const handleSelectedSite = (selectedId?: number) => {
    if (selectedId) {
      const selectedSiteData = sitesData.filter((theData) => theData.data_id === selectedId)[0];
      updateGlobalContext({ gSelectedSite: selectedSiteData });
      navigate("/func");
    } else {
      getFetchFunc('database/site/getlist').finally(() => { });
    }
  };

  const handleSiteAdderOpen = () => {
    setIsSiteAdderOpen(true);
  };

  const handleSiteAdderClose = () => {
    setIsSiteAdderOpen(false);
  };

  const closeSpinner = useCallback(() => {
    setSpinnerMessage("");
    setIsSpinnerOpen(false);
  }, []);

  const onSpinnerClose = () => {
    closeSpinner();
  };

  const handleSiteAdderData = () => { };

  return (
    <div className={style.layout}>
      {isSiteAdderOpen && <SiteAdder isOpen={isSiteAdderOpen} onClose={handleSiteAdderClose} cbFunc={handleSiteAdderData} />}
      {isSpinnerOpen && (
        <Spinner isOpen={isSpinnerOpen} onClose={onSpinnerClose} isHideTopMenu={true} message={spinnerMessage} />
      )}
      <HeaderLv1 selectedMenu="Site Selector" />
      <div className={style.content}>
        <div className={style.card__container}>
          {sitesData.map((theSiteData) => {
            const txtTitleValuePairJson = {
              Created: theSiteData.created_utc_datetime.slice(0, 10),
              Updated: theSiteData.updated_utc_datetime.slice(0, 10),
            };
            return (
              <SelectCard
                key={theSiteData.data_id}
                dataId={theSiteData.data_id}
                cbFunc={handleSelectedSite}
                imgSubUrl={siteImgSrc[theSiteData.name]}
                title={theSiteData.name}
                txtTitleValuePairJson={txtTitleValuePairJson}
                txtOnMouseOver={theSiteData.description}
                isDeleteFuncOn={!!gUserAuthDetail?.is_master}
              />
            );
          })}
          {gUserAuthDetail && gUserAuthDetail.is_master && <SelectCardAdd cbFunc={handleSiteAdderOpen} />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SiteSelector;
