import React, { useEffect, useRef, useState } from "react";
import Header from "./L0200_Header";
import ViewerMenu from "./L4100_ViewerMenu";
import Footer from "./L0300_Footer";
import styles from "./L4000_ViewerPage.module.css";
import ViewerContent from "./L4200_ViewerContent";
import { renderingConsoleLog } from "../utils/commonFunctions";
import { useGlobalContext } from "../context/GlobalContext";

const ViewerPage = () => {
  /***** Performance Checker ***************/
  const renderCounter = useRef<number>(0);
  renderingConsoleLog({ componentName: "Main", cnt: renderCounter.current += 1 })

  const { updateGlobalContext } = useGlobalContext();


  return (
    <div className={styles.layout}>
      <Header selectedMenu="Data Viewer" />
      <div className={styles.center}>
        <ViewerMenu />
        <ViewerContent />
      </div>
      <Footer />
    </div>
  );
};

export default ViewerPage;
