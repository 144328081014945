import { ObjectInterface } from "../interfaces";


/*******************************************
 * S: Date Related Functions
 ****************************************/

/**
 * Checks if a given date is within a specified range.
 * 
 * @param checkingDate - The date to check, in UTC ISO string format.
 * @param fromDate - The start of the date range, in UTC ISO string format.
 * @param toDate - The end of the date range, in UTC ISO string format.
 * @returns true if checkingDate is between fromDate and toDate, inclusive; otherwise, false.
 */
export function isDateWithinRange(checkingDate: string, fromDate: string, toDate: string): boolean {
  const checkDate = new Date(checkingDate).getTime();
  const startDate = new Date(fromDate).getTime();
  const endDate = new Date(toDate).getTime() + 24 * 60 * 60 * 1000;

  if (isNaN(checkDate) || isNaN(startDate) || isNaN(endDate)) {
    throw new Error('One or more dates are invalid. Please provide valid UTC ISO string dates.');
  }

  return checkDate >= startDate && checkDate <= endDate;
}


/**
 * return a file name only from the hyperlink 
 * ex:  /db/task/cam_images/cam_1711587698_thumb.jpg ->  cam_1711587698_thumb.jpg
 * 
 * @param url - hyperlink of the file
  * @returns filename 
 */
export function getFileName(url: string): string {
  const parts = url.split('/');
  return parts[parts.length - 1];
}



/**
 * return a api url from file hyperlink in old db (before 2024.04.30) 
 * ex:  /db/task/cam_images/cam_1711587698_thumb.jpg ->  file/{data_type}/{site_name}/cam_1711587698_thumb.jpg
 * 
 * @param baseUrl
 * @param site_name 
 * @param data_type
 * @param file_hyperlink - hyperlink of the file
  * @returns filename 
 */
export function getUrlFromOldFileHyperLink(baseUrl: string, data_type: string, site_name: string, file_hyperlink: string): string {
  const fileNameOnly = getFileName(file_hyperlink)
  const imgUrl = `${baseUrl}/file/${data_type}/${site_name}/${fileNameOnly}`

  return imgUrl
}

/**
 * return boolean value after checking the given string is valid json or not
 * 
 * @param str - string required to check whether it is json or not
 * @return true/false
*/
export function isJson(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}


/**
 * return user friendly date text from isoDate
 * 
 * @param isoDate - Date objecct 
 * @param isKorean - true for Day in korean 
 * @returns date string (string).
 */
export function convertIsoDateToYYYYDDMM(isoDate: string | Date, isKorean: boolean) {
  if (typeof isoDate === "string") {
    isoDate = new Date(isoDate);
  }

  const year = isoDate.getFullYear();
  const month = (isoDate.getMonth() + 1).toString().padStart(2, "0");
  const day = isoDate.getDate().toString().padStart(2, "0");
  const hours = isoDate.getHours().toString().padStart(2, "0");
  const minutes = isoDate.getMinutes().toString().padStart(2, "0");

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const daysK = ["일", "월", "화", "수", "목", "금", "토"];

  let weekDay;

  if (isKorean) {
    weekDay = daysK[isoDate.getDay()];
  } else {
    weekDay = days[isoDate.getDay()];
  }

  const updatedDateString = `${year}-${month}-${day} (${weekDay}) ${hours}:${minutes}`;

  return updatedDateString;
}


/**
 * convert UTC date to local in user friendly date text
 * 
 * @param sUTCstringTime - UTC iso string regardless "Z"
 * @param isKorean - true for Day in Korean
 * @returns date string (string).
 */
export const convertUTCsDateTimeToLocal = (sUTCstringTime: string, isKorean?: boolean): string => {
  const isThereZ = (sUTCstringTime.slice(-1) === "Z") ? true : false;
  let updatedDate: Date;

  if (isThereZ) {
    updatedDate = new Date(sUTCstringTime);
  } else {
    const utcgap_in_minutes = -new Date().getTimezoneOffset();
    const date = new Date(sUTCstringTime);
    updatedDate = new Date(date.getTime() + utcgap_in_minutes * 60 * 1000);
  }

  // console.log("updated Date:", sUTCstringTime.slice(-1), ", ", isThereZ, ", ", sUTCstringTime, ",", updatedDate.toString());

  return convertIsoDateToYYYYDDMM(updatedDate, isKorean ? isKorean : false);
};


export const convertUTCsDateTimeListToLocal = (
  sUTClist: string[]
): string[] => {
  const newTimeList = sUTClist.map((sUTC) => convertUTCsDateTimeToLocal(sUTC));
  return newTimeList;
};

/*******************************************
 * E: Date Related Functions
 ****************************************/




/*******************************************
 * S: Object/Array Related Functions
 ****************************************/

export function convertArrayToObject<T>(theArray: T[], createIndex = true): ObjectInterface {
  const theObject: ObjectInterface = {};
  theArray.forEach((item, index) => {
    const strItem = item as string;

    if (createIndex) {
      theObject[index.toString()] = strItem;
    } else {
      theObject[strItem] = strItem;
    }

  });
  return theObject;
}

export function areArraysEqual<T>(arr1: T[], arr2: T[]) {
  if (arr1.length !== arr2.length) return false;
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();
  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};


export function isEmpty(value: any) {
  if (typeof value === "object") {
    if (value === null) {                      //case of 'null'
      return true;
    } else if (Array.isArray(value)) {          //case of 'array'
      return (value.length === 0)
    } else {                                    //case of 'object'
      return Object.keys(value).length === 0
    }
  } else if (typeof value === "string") {       //case of 'string'
    return (value.trim() === "")
  } else if (typeof value === "number" ||
    typeof value === "boolean") {     //case of 'number' || 'boolean'
    return true;
  } else {
    return value ? false : true; // for others, default check
  }
}

export function isVoid(value: any) {
  if (typeof value === "object") {
    if (value === null) {                      //case of 'null'
      return true;
    } else if (Array.isArray(value)) {          //case of 'array'
      return (value.length === 0)
    } else {                                    //case of 'object'
      return Object.keys(value).length === 0
    }
  } else if (typeof value === "string") {       //case of 'string'
    return false;
  } else if (typeof value === "number" ||
    typeof value === "boolean") {     //case of 'number' || 'boolean'
    return true;
  } else {
    return value ? false : true; // for others, default check
  }
}

export function compareItemsInTwoArray<T>(searchingArray: T[], searchedArray: T[]) {
  return searchingArray.some(item => searchedArray.includes(item));
}

/*******************************************
 * E: Object/Array Related Functions
 ****************************************/


/*******************************************
 * S: ETC
 ****************************************/

export function getUniqueID() {
  return Date.now().toString();
}


export function degreeToRadian(degree: number) {
  return degree * (Math.PI / 180);
}

export function radianToDegree(radian: number) {
  return radian * (180 / Math.PI);
}


export function renderingConsoleLog({ componentName, cnt }: { componentName: string, cnt: number }) {
  // console.log(`[DEBUG]#${cnt} RENDERING-${componentName}`);
}

/*******************************************
 * E: ETC
 ****************************************/
