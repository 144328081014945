import React, { useEffect, useState } from "react";
import defaultStyle from './C0350_ImageFileSelector.module.css'
import { ObjectInterface } from "../interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-regular-svg-icons";


interface ImageFileSelectorProps {
  styleObj?: ObjectInterface;
  cbFunc: (dataUrl: string) => void;
}


const ImageFileSelector: React.FC<ImageFileSelectorProps> = ({
  styleObj,
  cbFunc,
}) => {

  /****************************
* Set styles custom / default
***********************************/
  const [style, setStyle] = useState<ObjectInterface>({});

  useEffect(() => {
    if (styleObj) {
      setStyle(styleObj);
    } else {
      setStyle(defaultStyle);
    }

  }, [styleObj])
  /***************************/

  // const [imageFile, setImageFile] = useState<File>();
  const [imageSrc, setImageSrc] = useState<String | null>();

  function createFileInput(): HTMLInputElement {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = false;
    fileInput.accept = "image/*"
    fileInput.style.display = 'none';
    fileInput.click();
    return fileInput;
  }

  function selectFile() {
    const theSelectedFile = createFileInput();
    theSelectedFile.addEventListener('change', async (e) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async () => {
          const readerStr = reader.result as string;
          setImageSrc(readerStr);
          cbFunc(readerStr)
        }

        const theFile = theSelectedFile.files ? theSelectedFile.files[0] : null;

        if (theFile) {
          // setImageFile(theFile)
          console.log(theFile);
          reader.readAsDataURL(theFile)
        }
      })

    })
  }


  return (
    <div className={style.layout} onClick={selectFile}>
      {imageSrc && <img className={style.selected__image} src={imageSrc as string} alt='selected_photo' />}
      {!imageSrc && <FontAwesomeIcon icon={faImage} />}
    </div>
  )
}

export default ImageFileSelector;