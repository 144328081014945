import React, { useCallback, useEffect, useRef, useState } from "react";
import { ObjectInterface } from "../interfaces";
import defaultStyle from './C0340_ThermalViewer.module.css'
import useGetFetch from "../hooks/useGetFetch";
import SpinnerChild from "./C0110_SpinnerChild";
import imgScalebar from "../assets/images/scale_bar.png";
import { isEmpty } from "../utils/commonFunctions";
import useGetFetch2 from "../hooks/useGetFetch2";

interface FetchThermal {
  result: boolean;
  data: null | undefined | {
    image: string;
    kalvin100: number[][];
    min_max_temp_in_celsius: number[];
  }
}

interface ThermalViewerProps {
  styleObj?: ObjectInterface;
  tempRange?: [number];
  siteFolderName: string;
  fileName: string;
}

const ThermalViewer: React.FC<ThermalViewerProps> = ({
  styleObj,
  siteFolderName,
  fileName,
  tempRange,
}) => {

  /****************************
  * Set styles custom / default
  ***********************************/
  const [style, setStyle] = useState<ObjectInterface>({});

  useEffect(() => {
    if (styleObj) {
      setStyle(styleObj);
    } else {
      setStyle(defaultStyle);
    }

  }, [styleObj])
  /***************************/


  const { data: thermalData, loading, error, getFetchFunc } = useGetFetch2<FetchThermal>();

  useEffect(() => {
    if (isEmpty(siteFolderName) || isEmpty(fileName)) return;
    const url = `database/task/parser/thermal?site_folder_name=${siteFolderName}&file_name=${fileName}`
    getFetchFunc(url).finally(() => { });
  }, [siteFolderName, fileName]);


  const [imgSrc, setImgSrc] = useState<string>("");
  const [kalvin100, setKanvin100] = useState<number[][]>();
  const [minMaxTemp, setMinMaxTemp] = useState<number[]>();


  useEffect(() => {
    if (thermalData) {
      closeSpinner();
      if (thermalData.result) {
        const data = thermalData.data

        if (data) {
          setImgSrc(data.image);
          setKanvin100(data.kalvin100);

          const minMax = getMinMaxFromArray(data.kalvin100);
          const minTemp = Math.round((minMax[0] - 27315) / 100 * 10) / 10
          const maxTemp = Math.round((minMax[1] - 27315) / 100 * 10) / 10
          setMinMaxTemp([minTemp, maxTemp]);
        }
      } else {

      }
    }

  }, [thermalData])

  const getMinMaxFromArray = useCallback((array: number[][]) => {
    const min = Math.min(...array.flat())
    const max = Math.max(...array.flat())
    return [min, max]
  }, [])

  useEffect(() => {
    openSpinner("Loading thermal data..");
  }, []);

  const [isSpinnerOpen, setIsSpinnerOpen] = useState(true);
  const [spinnerMessage, setSpinnerMessage] = useState<string>("");

  const openSpinner = useCallback((message: string) => {
    setSpinnerMessage(message);
    setIsSpinnerOpen(true);
  }, []);

  const closeSpinner = useCallback(() => {
    setSpinnerMessage("");
    setIsSpinnerOpen(false);
  }, []);


  const onSpinnerClose = () => {
    closeSpinner();
  }

  const [strPickedTemp, setStrPickedTemp] = useState<string>("-");
  const [stylePickedtemp, setStylePickedTemp] = useState<React.CSSProperties>();
  const [scaleMarkerLocation, setScaleMarkerLocation] = useState<string>("0%");

  const imageRef = useRef<HTMLImageElement>(null);

  const handleMouseOverOnImage = (e: React.MouseEvent<HTMLImageElement>) => {
    // console.log(e);
    const img = imageRef.current;

    if (!img || !kalvin100 || !minMaxTemp) return;

    const imgRect = img.getBoundingClientRect()


    const dx = e.clientX - imgRect.x;
    console.log(`e.clientX:${e.clientX.toFixed(1)}, imgRect.x: ${imgRect.x.toFixed(1)}`);

    const dy = e.clientY - imgRect.y;
    const imgWidth = imgRect.width;
    const imgHeight = imgRect.height;

    console.log(`imgWidth: ${imgWidth.toFixed(1)}, dx: ${dx.toFixed(1)}`)

    const theStylePickedTemp = { left: `${Math.round(dx + 30)}px`, top: `${Math.round(dy - 30)}px` };

    setStylePickedTemp(theStylePickedTemp);

    const thermalWidth = kalvin100[0].length
    const thermalHeight = kalvin100.length

    let pointingX = Math.round(dx / imgWidth * thermalWidth) - 1;
    pointingX = pointingX >= 0 ? pointingX : 0;
    pointingX = pointingX < thermalWidth ? pointingX : thermalWidth - 1;

    let pointingY = Math.round(dy / imgHeight * thermalHeight) - 1;
    pointingY = pointingY >= 0 ? pointingY : 0;
    pointingY = pointingY < thermalHeight ? pointingY : thermalHeight - 1;


    // console.log("PointingXY", pointingX, pointingY)

    const ifTempDataReady = kalvin100 && pointingY && pointingX && kalvin100[pointingY][pointingX]

    if (ifTempDataReady) {
      const temp = ((kalvin100[pointingY][pointingX] - 27315) / 100.0);
      setStrPickedTemp(`${temp}℃`);
      const minTemp = minMaxTemp[0]
      const maxTemp = minMaxTemp[1]

      let percentFromLeft = ((temp - minTemp) / (maxTemp - minTemp) * 100)
      percentFromLeft = percentFromLeft > 100 ? 100 : (percentFromLeft < 0 ? 0 : percentFromLeft)

      // console.log("DEBUG percentFromLeft", percentFromLeft.toFixed(1));

      setScaleMarkerLocation(`${percentFromLeft.toFixed(1)}%`);

    } else {
      setStrPickedTemp(`-`);
      setScaleMarkerLocation("0%");
    }



  }

  return (
    <div className={style.layout}>
      {isSpinnerOpen && <SpinnerChild
        message={spinnerMessage}
        isOpen={isSpinnerOpen}
        onClose={onSpinnerClose} />}
      {!isSpinnerOpen && imgSrc !== "" && minMaxTemp &&
        <>
          <div className={style.image__container}>
            <div className={style.image_wrapper}>
              <div className={style.scalebar__current__temp} style={stylePickedtemp}>{strPickedTemp}</div>
              <img
                ref={imageRef}
                className={style.image}
                src={`data:image/jpeg;base64,${imgSrc}`}
                alt="thermal"
                onMouseMove={handleMouseOverOnImage}
              />
            </div>
          </div>
          <div className={style.scalebar__container}>
            <label className={style.scalebar__label}>Min: {minMaxTemp[0]}</label>
            <div className={style.scalebar__image__container}>
              <img className={style.scalebar__image} src={imgScalebar} alt="scalebar" />
              <span className={style.scalebar__marker} style={{ left: scaleMarkerLocation }}>|</span>
            </div>
            <label className={style.scalebar__label}>Max:{minMaxTemp[1]}</label>
          </div>

        </>}
    </div>
  )

}

export default ThermalViewer;