import React, { useEffect, useRef, useState } from "react";
import styles from "./L0050_SpinnerLayout.module.css";
import { UPDATE_DATE } from "../utils/commonConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../components/C0100_Spinner";

const SpinnerLayout = () => {
  return (
    <Spinner
      isOpen={true}
      onClose={function (): void {
        throw new Error("Function not implemented.");
      }}
      message="session transfering.."
    />
  );
};

export default SpinnerLayout;
