import { useEffect, useReducer } from "react";
import { useGlobalContext } from "../context/GlobalContext";
import { isEmpty } from "../utils/commonFunctions";
import { ERROR_CODE_SESSION_OUT } from "../utils/commonConstants";

const ACTIONS = {
  FETCHING: "fetching",
  DONE: "done",
  ERROR: "error",
};

interface State<T> {
  data: T | null | undefined;
  loading: boolean;
  error: Error | null;
}

interface Action<T> {
  type: string;
  payload?: T;
}

const initialState = {
  data: null,
  loading: false,
  error: null,
};

function reducer<T>(state: State<T>, action: Action<T>): State<T> {
  switch (action.type) {
    case ACTIONS.FETCHING:
      return { ...state, data: null, loading: true };
    case ACTIONS.DONE:
      return { ...state, data: action.payload, loading: false };
    case ACTIONS.ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload as Error,
      };
    default:
      return state;
  }
}

function useGetFetch<T>(subUrl: string, mandatories: any[]) {
  const { gApiKey, gBaseUrl, updateGlobalContext, gSessionToken } = useGlobalContext();

  const [state, dispatch] = useReducer<
    (state: State<T>, action: Action<T>) => State<T>
  >(reducer, initialState);

  useEffect(() => {
    let isReady: boolean = true;

    if (!subUrl) {
      isReady = false;
    } else {

      // check mandatories which are all mandatory for query
      for (let i = 0; i < mandatories.length; i++) {
        const dep_item = mandatories[i];
        if (isEmpty(dep_item)) {
          isReady = false;
          break;
        }
      }
    }

    if (isReady) {
      dispatch({ type: ACTIONS.FETCHING });

      const targetUrl = `${gBaseUrl}/${subUrl}`;

      console.log("[DEBUG] Fetch On:", subUrl, ` [${mandatories}]`);

      fetch(targetUrl, {
        method: "GET",
        headers: {
          "X-API-Key": gApiKey,
          'Authorization': `Bearer ${gSessionToken}`
        },
      })
        .then((res) => {
          if (res.ok) return res.json();

          if (res.status === ERROR_CODE_SESSION_OUT) {
            console.log("res.status === ERROR_CODE_SESSION_OUT")
            updateGlobalContext({ gIsLoggedIn: false })
          }

          throw new Error("Error in fetching");
        })
        .then((resJson) => {
          dispatch({ type: ACTIONS.DONE, payload: resJson });
        })
        .catch((e) => {
          dispatch({ type: ACTIONS.ERROR, payload: e.error });
        });
    } else {
      console.log("[DEBUG] Fetch Off:", subUrl, ` [${mandatories}]`);
      // dispatch({ type: ACTIONS.ERROR });
    }
  }, [gApiKey, gBaseUrl, gSessionToken, subUrl, updateGlobalContext]);

  return state;
}

export default useGetFetch;
