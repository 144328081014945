import React, { useState, memo } from "react";
import styles from "./C0441_ToggleButtonGroupDetailViewer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ObjectInterface } from "../interfaces";

interface ToggleButtonGroupProps {
  items: ObjectInterface;
  disabledKeys: string[];
  initKey?: string;
  cbFunc: (key: string, value: string) => void;
  layoutStyle?: string;
  itemLoading: boolean;
  willDisplayKey?: boolean;
}

const ToggleButtonGroupDetailViewer: React.FC<ToggleButtonGroupProps> = ({
  items,
  disabledKeys,
  initKey,
  cbFunc,
  layoutStyle,
  itemLoading,
  willDisplayKey,
}) => {
  const [selectedKey, setSelectedKey] = useState<string>(initKey ? initKey : "");

  willDisplayKey = willDisplayKey ? true : false;

  const handleItemClick = (key: string, value: string) => {
    if (selectedKey === key) {
      setSelectedKey("");
      cbFunc("", "");
    } else {
      setSelectedKey(key);
      cbFunc(key, value);
    }
  };



  const theLayoutStyle = `${layoutStyle ? layoutStyle : ""} ${styles.layout ? styles.layout : ""}`

  return (
    <div className={theLayoutStyle}>
      <div className={styles.toggle__container}>
        {!itemLoading &&
          items &&
          Object.keys(items).map((key) => (
            <button
              key={key}
              className={`${styles.toggle__button} ${disabledKeys.includes(key) ? styles.disabled : (selectedKey === key ? styles.active : "")}`}
              onClick={() => handleItemClick(key, items[key])}
              disabled={disabledKeys.includes(key) ? true : false}
            >
              {willDisplayKey ? key : items[key]}
            </button>
          ))}
      </div>
    </div>
  );
};

export default memo(ToggleButtonGroupDetailViewer);
