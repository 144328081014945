import React, { useEffect, useState } from "react";
import { ObjectInterface } from "../interfaces";
import defaultStyle from "./C0320_SensorViewer.module.css"
import { useGlobalContext } from "../context/GlobalContext";
import { SENSOR_COLOR_CODE, SENSOR_DATA } from "../utils/commonConstants";

interface SensorData {
  [key: string]: string | number;
}

interface SensorViewerProps {
  styleObj?: ObjectInterface;
  sensorData: SensorData;
}

const SensorViewer: React.FC<SensorViewerProps> = ({
  styleObj,
  sensorData,
}) => {
  /****************************
 * Set styles custom / default
 ***********************************/
  const [style, setStyle] = useState<ObjectInterface>({});

  useEffect(() => {
    if (styleObj) {
      setStyle({ ...defaultStyle, ...styleObj });
    } else {
      setStyle(defaultStyle);
    }

  }, [styleObj])
  /***************************/


  const { } = useGlobalContext();

  return (
    <div className={style.layout}>
      {
        Object.entries(SENSOR_DATA).map(([sensorType, detail]) => {

          let displayValue: string = "--";
          let thisColor: string = SENSOR_COLOR_CODE.normal;


          if (!sensorData[sensorType]) {

          } else if (typeof sensorData[sensorType] === 'number') {
            const originalValue = sensorData[sensorType] as number;
            displayValue = originalValue.toFixed(2);

            if (detail.minWarningValue && (originalValue <= detail.minWarningValue)) thisColor = SENSOR_COLOR_CODE.warning;
            if (detail.maxWarningValue && (originalValue >= detail.maxWarningValue)) thisColor = SENSOR_COLOR_CODE.warning;
            if (detail.minDangerValue && (originalValue <= detail.minDangerValue)) thisColor = SENSOR_COLOR_CODE.danger;
            if (detail.maxDangerValue && (originalValue >= detail.maxDangerValue)) thisColor = SENSOR_COLOR_CODE.danger;
          }

          return (
            <div key={sensorType} className={style.gauge__layout}>
              <div className={style.gauge__value} style={{ color: thisColor }}>{displayValue}</div>
              <div className={style.gauge__unit} dangerouslySetInnerHTML={{ __html: detail.htmlunit }}></div>
              <div className={style.gauge__title} dangerouslySetInnerHTML={{ __html: detail.htmltitle }}></div>
            </div>)
        })
      }
    </div>)
}

export default SensorViewer;